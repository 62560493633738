import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchGeolocationAndDetailsBgta } from './Functions/fetchGeolocationBgta'; // Adjust the path as needed
import LoadingComponent from './LoadingComponent'; 
import ReactTableCatastroBog from './Tables/ReactTableCatastroBog'; 
import L from 'leaflet';
import logo from "../images/logo.svg";


export default function InfoCatastro() {
  
  // define cities and their geolocations
  const cities = [
    { name: 'Bogota', geo: [4.624335, -74.063644] },
    // { name: 'Medellin', geo: [6.230833, -75.590553] },
    // { name: 'Cali', geo: [3.444921852109853, -76.51952404477846] },
    // { name: 'Barranquilla', geo: [10.995093120678971, -74.81329453834913] }
  ];

  // States variables
  const [selectedCity, setSelectedCity] = useState(cities[0].name);
  const [geolocationMap, setGeoLocationMap] = useState(cities[0].geo);
  const [address, setAddress] = useState('');      
  const [properties, setProperties] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [showMarker, setShowMarker] = useState(false); // State to control marker visibility

  // Function to handle the city change
  const handleCityChange = (event) => {
    const selectedGeo = JSON.parse(event.target.value);
    setSelectedCity(event.target.value);
    console.log('City geo selected:', selectedGeo);    
    setGeoLocationMap(selectedGeo);
  };
  

  // Function to change the view of the map 
  function ChangeView({ center }) {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);
    return null;
  }

  // Icon for the map marker
  const MapMarkerIcon = L.icon({
    iconUrl: logo,
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
  });

  // Function to handle the loading modal
  const handleClose = () => {
    setLoading(false);
  };



  // Function to handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();   
    console.log('Address submitted:', address);     
    setLoading(true);
    const result = await fetchGeolocationAndDetailsBgta(address);
    setLoading(false); 

    const geolocation = result?.fetchlotcodigo_result.geolocation || null;
    const properties = result?.properties.properties || [];

    if (geolocation) {
      console.log("geolocation from address:", geolocation);
      setGeoLocationMap(geolocation);
      setShowMarker(true);
    }

    console.log('properties in info catastro page:', properties);
    setProperties(properties);    
  };

  return (
    <div className="bg-white mx-auto max-w-9xl py-4 sm:px-6 lg:px-8 flex-1 mt-8" >

      <div className="flex justify-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-10">Consultar informacion Catastral</h1>
      </div>

      <div className="mx-auto max-w-2xl px-4 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
        {/* city dropdown menu */}
        <div className="mb-4">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            Seleccione una ciudad
          </label>
          <select
            id="city"
            name="city"
            value={selectedCity}
            onChange={handleCityChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 bg-slate-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {cities.map((city) => (
              <option key={city.name} value={JSON.stringify(city.geo)}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        
        {/* address input form */}
        <div className="mb-4">
          <form onSubmit={handleSubmit} className="space-y-2">
            <div className="py-4">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Escriba la dirección del inmueble
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
            <div>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Consultar
              </button>
            </div>
          </form>
          <LoadingComponent msg="Analizando..." show={loading} onClose={handleClose} /> {/* Show the loading modal when loading */}          
        </div>

        {/* map */}
        <div className="mb-4">
          <MapContainer center={geolocationMap} zoom={13} style={{ height: "400px", width: "100%" }}>
            <ChangeView center={geolocationMap} />
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"              
            />
            {showMarker && (
              <Marker position={geolocationMap} icon={MapMarkerIcon}>
                <Popup>
                  {address}
                </Popup>                
              </Marker>
            )}
          </MapContainer>
        </div>

        {/* properties table */}
        <div className="mb-4">
          <ReactTableCatastroBog properties={properties}/>
        </div>
      </div>
    </div>
  );
};
