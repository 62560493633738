import logo from "../../images/logo.svg";
import { EyeIcon, EyeSlashIcon, CheckCircleIcon} from '@heroicons/react/20/solid'
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

export default function ForgottenPassword() {

    const poolData = {
      UserPoolId: 'us-east-1_vtL6ckvXH',
      ClientId: '389jmmjrhkqclot34e7r9u863g',
    }; 
    var userPool = new CognitoUserPool(poolData);

    

    // React hooks
    const navigate = useNavigate();
    
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    
    const [ViewPasswordCriteria, setViewPasswordCriteria] = useState(false);
    const [validations, setValidations] = useState({
     lenght:false,
     uppercase:false,
     lowercase:false,
     number:false,
     symbol:false,
    });
    
    const [ChangePasswordForm, setChangePasswordForm]=useState(false);
    const [CodeConfirmation, setCodeConfirmation] = useState("");          
    const [ViewPassword,setviewPassword]=useState(false);
    const [ViewPasswordIcon,setviewPasswordIcon]=useState(EyeSlashIcon);    
    const [Password2, setPassword2] = useState("");
    const [PasswordMatch, setPasswordMatch]=useState(false);
    
    // Function to handle email input
    const onChangeEmail=(event)=>{
      setEmail(event.target.value);
    }

    //Function to request code, entering user email.
    const handleSubmitCodeRequest = event => {
      event.preventDefault(); 
           
      // Cognito user data
      const userData = {
        Username: Email, // the username of the user that wants to change password
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      
      cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + data);
        setChangePasswordForm(true);

      },
      onFailure: function(err) {
        alert("Ha superado el limite de intentos. Intente despúes de un tiempo.");
      },
    });           
     
    }
    
    // Function to handle password input
    const onChangePassword=(event)=>{
      const newPassword=event.target.value;      
      setPassword(newPassword);        
      
      if (!!newPassword){
        setViewPasswordCriteria(true); 
        //console.log("validation.length is "+ " " +newPassword.length );    
        setValidations({
          length: newPassword.length >= 6,
          uppercase: /[A-Z]/.test(newPassword),
          lowercase: /[a-z]/.test(newPassword),
          number: /[0-9]/.test(newPassword),
          symbol:/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(newPassword),
        });
          
      }              
      else setViewPasswordCriteria(false);    
    }
    
    // function to handle view password
    const handleViewPassword =()=>{
      setviewPassword(!ViewPassword);
      if (ViewPassword){setviewPasswordIcon(EyeSlashIcon)}
      else{setviewPasswordIcon(EyeIcon)}      
    }
    
    // Function to handle code confirmation input
    const onChangeCodeConfirmation=(event)=>{
      setCodeConfirmation(event.target.value);  
    }
 
    // Function to handle password confirmation input
    const onChangePassword2=(event)=>{
      const newPassword2=event.target.value; 
      setPassword2(newPassword2);
      
      if (Password===newPassword2){
        setPasswordMatch(true);
      }
      else setPasswordMatch(false);
    }

    // function to handle password change    
    const handleSubmitPasswordChange = event => {
      event.preventDefault();
      const userData = {
        Username : Email,
        Pool : userPool
      };

      const cognitoUser = new CognitoUser(userData);
      
      cognitoUser.confirmPassword(CodeConfirmation, Password, {
        onSuccess() {
          console.log("Password changed successfully");
          alert("La contraseña ha sido restablecida");  
          navigate("/"); 
        },
        onFailure(err) {
          console.error(err);
          alert("El codigo ingresado no es correcto");  
        }
      });
    }

    // function to resend code to email
    const handleResendCode = ()=>{
      setChangePasswordForm(false);
    }


    return (
      <>        
        
        {/* //email form */}     
        <div className={ChangePasswordForm ? "hidden" : "visible"}> 
          <div className="flex mx-auto min-w-max min-h-max flex-1 flex-col justify-center px-12 py-10 sm:py-10 md:pb-20 lg:px-12 bg-white/80">
            <div className="sm:w-full md:max-w-md sm:max-w-sm">                   
              <div className="flex justify-center visible md:invisible">
                <Link to="/">              
                  <div className="mt-4 flex items-center border-gray-500 ">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="habi logo"
                      />
                      <div className='ml-1 lg:mx-2 font-semibold text-xl pr-4 '>habi Data</div>
                  </div>
                </Link>
              </div> 
              <div className='flex font-bold text-2xl justify-center invisible md:visible'>Reestablecer contraseña</div>                   
            </div>        
    
            <div className="md:mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-4" onSubmit={handleSubmitCodeRequest}>                
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Ingresa tu correo electronico
                  </label>
                  <div className="">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={Email}
                      autoComplete="email"
                      required                    
                      className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={onChangeEmail}
                    />
                  </div>
                </div>                 
    
                <div>                             
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"             
                    >
                    Solicitar codigo para reestablecer contraseña
                  </button>               
                </div>
                
              </form>
    
              <p className="mt-1 text-center text-sm text-gray-500">
                Ya tiene una cuenta?{' '}
                <Link to="/" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  Ingresar
                </Link>
              </p>             

            </div>
          </div>
        </div>

        {/* //code validation form */}
        <div className={ChangePasswordForm ? "visible" : "hidden"}> 
          <div className="flex mx-auto min-h-max flex-1 flex-col justify-center px-12 py-10 sm:py-10 md:pb-20 lg:px-12 bg-white/80">
            <div className="sm:w-full md:max-w-md sm:max-w-sm">                   
              <div className="flex justify-center visible md:invisible">
                <Link to="/">              
                  <div className="mt-4 flex items-center border-gray-500 ">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="habi logo"
                      />
                      <div className='ml-1 lg:mx-2 font-semibold text-xl pr-4 '>habi Data</div>
                  </div>
                </Link>
              </div>             
            </div>        
    
            <div className="md:mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="block w-full rounded-md border-0 py-1.5">
                <div className='mt-10 mb-10 md:mt-0 flex font-bold text-2xl justify-center'>Reestablecer contraseña</div> 
                <p className="text-gray-900 shadow-sm sm:text-sm sm:leading-6">Se ha enviado un codigo a su correo electronico</p>
              </div>

              <form className="space-y-4" onSubmit={handleSubmitPasswordChange}>
                <div>
                  <label htmlFor="codigo" className="block text-sm font-medium leading-6 text-gray-900">
                    Ingrese el codido
                  </label>
                  <div className="mt-4">
                    <input
                      id="codigo"
                      name="codigo"
                      type="text"
                      value={CodeConfirmation}
                      autoComplete="no"
                      required
                      autoFocus
                      className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={onChangeCodeConfirmation}
                    />
                  </div>
                </div>  
                <div> 
                <div>
                  <div className="items-center">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Ingrese la nueva contraseña
                    </label>                  
                  </div>
                  
                  <div className="flex justify-between items-center bg-white rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm sm:leading-6">
                    <input
                      id="password"
                      name="password"
                      type={ViewPassword ? "text":"password"}
                      value={Password}
                      autoComplete="current-password"
                      required
                      className="ml-1.5 block w-full placeholder:text-gray-400 outline-none"
                      onChange={onChangePassword}                    
                    />
                    <div className="mr-1.5 bg-white">
                      <ViewPasswordIcon className="h-5 w-5 hover:cursor-pointer bg-white text-gray-500" onClick={handleViewPassword}/>                 
                    </div>
                  </div>                                          
                  
                  <div className={ViewPasswordCriteria ? "" : "hidden"}>
                    <div className="mt-2 text-xs font-sans text-slate-600">                
                      
                        <div className="flex items-center border-gray-500 ">
                          <CheckCircleIcon className={validations.length ? " h-5 w-5 text-green-500" :" h-5 w-5 text-red-500"}/>
                          <div className=''>Al menos 6 caracteres</div>
                        </div>
                        <div className="flex items-center border-gray-500 ">
                          <CheckCircleIcon className={validations.uppercase ? " h-5 w-5 text-green-500" :" h-5 w-5 text-red-500"}/>
                          <div className=''>Al menos una mayúscula:</div>
                        </div>
                        <div className="flex items-center border-gray-500 ">
                          <CheckCircleIcon className={validations.lowercase ? " h-5 w-5 text-green-500" :" h-5 w-5 text-red-500"}/>
                          <div className=''>Al menos una minúscula</div>
                        </div>
                        <div className="flex items-center border-gray-500 ">
                          <CheckCircleIcon className={validations.number ? " h-5 w-5 text-green-500" :" h-5 w-5 text-red-500"}/>
                          <div className=''>Al menos un numero</div>
                        </div>    
                        <div className="flex items-center border-gray-500 ">
                          <CheckCircleIcon className={validations.symbol ? " h-5 w-5 text-green-500" :" h-5 w-5 text-red-500"}/>
                          <div className=''>Al menos un caracter especial</div>
                        </div>                     

                    </div> 
                  </div>

                  <div>
                    <label htmlFor="Password2" className="block text-sm font-medium leading-6 text-gray-900">
                      Confirme la contraseña
                    </label>
                    <div className="">
                      <input
                        id="Password2"
                        name="Password2"
                        type="text"
                        value={Password2}
                        autoComplete="no"
                        required                        
                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={onChangePassword2}
                      />
                    </div>
                  </div>
                  
                </div>


                { PasswordMatch ? (
                  <button
                    type="submit"
                    className="flex w-full mt-6 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"             
                    >
                    Cambiar contraseña
                  </button>
                ):" "}
                </div>

              </form>

              <div className="flex m-1 mt-6 text-s">
                <button className="font-semibold text-slate-500 hover:text-indigo-900" onClick={handleResendCode}>
                  Reenviar el codigo nuevamente
                </button>                    
              </div>     
                  

            </div>
          </div>
        </div>
      </>
    )
  }
  