import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { FolderArrowDownIcon } from '@heroicons/react/24/outline';

const ReactTableComparablesVenta = ({ comparables }) => {
  // console.log('comparables in reacttable:', comparables);

  const columnsToShow = useMemo(() => [
    'Tipo',
    '$ COP', // Add the new column here
    'Area (m2)',
    '$ COP/m2', // Changed the name from '$ MM COP/m2'        
    'Baños',
    'Garajes',
    'Estrato',    
    'Antiguedad',
    'Latitud',
    'Longitud',
  ], []);

  // Prepare the data
  const data = useMemo(() => {
    return comparables.map(comparable => {
      const row = {};
      columnsToShow.forEach(col => {
        if (col === '$ COP') {
          // Calculate '$ COP' as the product of '$ MM COP/m2' * 1000000 and 'Area (m2)'
          const pricePerM2 = comparable['$ MM COP/m2'] ? comparable['$ MM COP/m2'] : 0; // Convert to '$ COP/m2'
          const area = comparable['Area (m2)'];
          row[col] = pricePerM2 && area ? Number(pricePerM2 * area).toLocaleString() : null;
        } else if (col === '$ COP/m2') {
          // Multiply the original '$ MM COP/m2' value by 1,000,000 to convert to '$ COP/m2'
          row[col] = comparable['$ MM COP/m2'] ? Number(Math.round(comparable['$ MM COP/m2'])).toLocaleString() : null;
        } else {
          row[col] = comparable[col];
        }
      });
      return row;
    });
  }, [comparables, columnsToShow]);

  // Define the columns dynamically
  const columns = useMemo(() => {
    return columnsToShow.map(col => {
      if (col === '$ COP/m2') {
        return {
          Header: col,
          accessor: col,
          Cell: ({ value }) => (
            <div className='bg-blue-50'>
              {value}
            </div>
          ),
        };
      }
      return {
        Header: col,
        accessor: col,
      };
    });
  }, [columnsToShow]);

  // Use the useTable hook to create the table instance
  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
    XLSX.writeFile(workbook, 'data_comparables_venta.xlsx');
  };

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className='p-2 font-bold text-white bg-blue-800'>Comparables en Venta</div>

        <table className='flex-1 w-full border-collapse' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className='p-2 text-center bg-blue-900 text-white font-semibold sticky top-0'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td className='text-center' {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* download button */}
      <div className='flex justify-start'>
        <button
          onClick={exportToExcel}
          className='flex my-4 p-2 border-2 border-slate-500 rounded-lg hover:bg-indigo-800 hover:text-white'
        >
          <FolderArrowDownIcon className='h-6 w-6 mr-2' aria-hidden='true' />
          Descargar
        </button>
      </div>
    </div>
  );
};

export default ReactTableComparablesVenta;
