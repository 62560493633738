import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const areas_dict_total = {
    'apartamento': {
        "Micro": [0, 30],
        "Pequeño": [30, 60],
        "Mediano": [60, 120],
        "Grande": [120, 250],
        "Extra grande": [250, 1000]
    },
    'casa': {
        "Micro": [0, 50],
        "Pequeño": [50, 120],
        "Mediano": [120, 250],
        "Grande": [250, 500],
        "Extra grande": [500, 1000],
        "Super grande": [1000, 2000],
        "Mega grande": [2000, 10000]
    },
};

// Function to calculate a moving average (while retaining the first non-null value)
const movingAverage = (data, windowSize) => {
  let result = Array(data.length).fill(null);

  if (data.length > 0) {
    // Keep the first non-null value
    const firstValueIndex = data.findIndex(val => val !== null);
    if (firstValueIndex !== -1) {
      result[firstValueIndex] = data[firstValueIndex];
    }

    // Apply the moving average after the first non-null value
    for (let i = firstValueIndex + 1; i < data.length; i++) {
      let sum = 0;
      let count = 0;

      // Calculate the average based on the window size
      for (let j = 0; j < windowSize && i - j >= firstValueIndex; j++) {
        sum += data[i - j];
        count++;
      }

      result[i] = sum / count;
    }
  }

  return result;
};

const PlotPricesByArea = ({ prices_to_plot, propertyType, area }) => {
  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    const areas_dict = areas_dict_total[propertyType];

    if (prices_to_plot && areas_dict && Object.keys(prices_to_plot).length > 0) {
      const months = Object.keys(prices_to_plot);
      const categories = Object.keys(areas_dict);

      // Determine which categories to plot based on the passed area
      let selectedCategories = categories;
      if (area !== undefined) {
        selectedCategories = categories.filter(category => {
          const [min, max] = areas_dict[category];
          return area >= min && area <= max;
        });
      }

      const data = selectedCategories.map((category, index) => {
        const yValues = months.map(month => prices_to_plot[month]?.[category] || null);

        // Fill in missing values to maintain continuous lines
        for (let i = 1; i < yValues.length; i++) {
          if (!yValues[i]) {
            yValues[i] = yValues[i - 1];
          }
        }

        // Calculate the moving average to represent the trendline
        const smoothedValues = movingAverage(yValues, 6); // 6-month moving average

        return {
          x: months,
          y: smoothedValues,
          type: 'scatter',
          mode: 'lines',  // Use 'lines' for trendlines
          name: category,  // Keep the category name in the legend
          line: {
            color: area === undefined ? `hsl(${index * 60}, 70%, 50%)` : 'blue-400', // Different colors for categories
          },
          hovertemplate: 'fecha: %{x}<br>Trend: $ %{y:,.1f} MM COP/m2',
        };
      });

      const layout = {
        title: area !== undefined 
          ? `$MM COP/m2 promedio venta en los últimos 5 años para áreas cercanas a ${area} m2` 
          : `$MM COP/m2 promedio venta en los últimos 5 años por tamaños de área `,
        xaxis: {
          title: '',
          tickangle: 0,
          tickfont: { size: 10 },
        },
        yaxis: {
          title: '',
          tickfont: { size: 12 },
        },
        legend: {
          title: {
            text: 'Tamaños de área',
          },
        },
        modebar: {
          orientation: 'v',
          x: 1,
          xanchor: 'right',
          y: 1,
          yanchor: 'top',
        },
        margin: { t: 50, b: 50, l: 50, r: 50 },
      };

      setPlotData({ data, layout });
    } else {
      console.warn("prices_to_plot or areas_dict is undefined or empty");
      setPlotData(null);
    }
  }, [prices_to_plot, propertyType, area]);

  return (
    <div style={{ maxWidth: '100%', width: '100%', padding: '0 20px', boxSizing: 'border-box' }} className='mt-2'>
      {plotData ? (
        <Plot 
          data={plotData.data} 
          layout={plotData.layout} 
          useResizeHandler={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <p>No hay suficiente informacion para construir una grafica de comportamiento de precios con relacion al area indicada</p>
      )}
    </div>
  );
};

export default PlotPricesByArea;
