import React, { useState, useEffect,useRef } from 'react';
import { GeocordinatesCol } from './Functions/fetchData';  
import { MapContainer, TileLayer, Marker, Popup, useMap, FeatureGroup, Circle } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import logo from "../images/logo.svg";
import { MapPinIcon } from '@heroicons/react/24/outline'
import LoadingComponent from './LoadingComponent'; 

export default function LocationInputForm({ onSubmit,turnCreatePolygon, markersVenta, markersRenta, citiestoShow }) {
  
  // based on the citiestoShow, the cities will be shown in the dropdown menu based on citiesList
  const selectedCities=citiestoShow

  // define cities and their geolocations
  const citiesList = [
    { name: 'Bogota', geo: [4.624335, -74.063644] },
    { name: 'Barranquilla', geo: [10.995093120678971, -74.81329453834913] },
    {name: 'Bello', geo: [6.336545104648865, -75.56026763983853] },
    { name: 'Cali', geo: [3.444921852109853, -76.51952404477846] },
    { name: 'Chia', geo: [4.862877562187854, -74.05882979214799] },
    {name: 'Envigado', geo: [6.167542096948573, -75.58423601622303] },    
    { name: 'Funza', geo: [4.719302147505335, -74.20386283409933] },    
    {name: 'Itagui', geo: [6.16853223171493, -75.62127773494451] },
    {name: 'La Estrella', geo: [6.153513366206136, -75.63656327920815] },
    { name: 'Medellin', geo: [6.230833, -75.590553] },   
    { name: 'Mosquera', geo: [4.701591611351546, -74.24441983263111] },
    {name: 'Sabaneta', geo: [6.150923897915532, -75.616605494924] },    
    { name: 'Soacha', geo: [4.582120583316292, -74.2129567923488] },
   
  ];

  const cities = citiesList.filter(city => selectedCities.includes(city.name));
  
  
  // States variables
  const [selectedCity, setSelectedCity] = useState(cities[0].name);
  const [geolocationMap, setGeoLocationMap] = useState(cities[0].geo);
  const [address, setAddress] = useState('');      
  const [zoom, setZoom] = useState(13);
  const [properties, setProperties] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [showCenterMarker, setShowMarker] = useState(false); // State to control marker visibility 
  const [editMarker, setEditMarker] = useState(false); // State to control marker edit
  const featureGroupRef = useRef(null); // Create a ref for the FeatureGroup
  const [polygon, setPolygon] = useState(null);      

  
  const [propertyType, setpropertyType] = useState('');    
  const [baths, setBaths] = useState('');
  const [rooms, setRooms] = useState('');
  const [age, setAge] = useState('');
  const [area, setArea] = useState('');
  const [stratum, setStratum] = useState(''); 
  const [garages, setGarages] = useState('');
  const [elevator, setElevator] = useState('');

  const [markers, setMarkers] = useState([]);
  const [hideForm, setHideForm] = useState(false);

  
  // Function to handle the city change
  const handleCityChange = (event) => {       
    setAddress(''); // Reset the address input
    setpropertyType(''); // Reset the propertyType input
    setBaths(''); // Reset the baths input
    setRooms(''); // Reset the rooms input
    setArea(''); // Reset the area input
    setStratum(''); // Reset the stratum input
    setGarages(''); // Reset the garages input
    setElevator(''); // Reset the elevator input
    setAge(''); // Reset the age input
    setPolygon(null); // Reset the polygon state
    setZoom(13); // Reset the zoom level
    setShowMarker(false); // Hide the marker when changing the city
    setEditMarker(false); // Disable the marker edit option
    onSubmit(null); // Pass null to the parent component
    setMarkers([]);
      

    // console.log('City selected:', event.target.value); 
    const selection = JSON.parse(event.target.value);
    const selectedCity = selection.name;
    const selectedGeo = selection.geo;
    
    
    setSelectedCity(selectedCity);
    // console.log('City geo selected:', selectedCity);    
    // console.log('City geo selected:', selectedGeo);    
    setGeoLocationMap(selectedGeo);
  };
  
  // Function to change the view of the map 
  function ChangeView({ center }) {
    const map = useMap();
    useEffect(() => {      
      map.setView(center, zoom); 
    }, [center, zoom, map]);
    return null;
  }

  // Icon for the map marker
  const MapMarkerIcon = L.icon({
    iconUrl: logo,
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor    
  });

  // Function to handle the loading modal
  const handleClose = () => {
    setLoading(false);
  };

  // Function to handle the form submission  
  const handleUbicarMapa = async (e) => {
    e.preventDefault();   
    console.log('Address submitted:', address);     
    setLoading(true);
    console.log('selectedCity:', selectedCity);

    let geolocation = [];

    try {        
        const result = await GeocordinatesCol(address, selectedCity);
        // console.log('GeocordinatesBog result:', result);
        geolocation = result || null;

        // if geolocation has values different from [null, null] or empty array do something
        if (geolocation && Array.isArray(geolocation) && geolocation.length === 2 && !geolocation.includes(null)) {
          // console.log('GeocordinatesBog result:', result);
          // If geolocation is valid, proceed with setting the map view
          setGeoLocationMap(geolocation);
          setShowMarker(true);   
          setZoom(16); // zoom=16 to show the circle radius at 500m
        } else {
          alert('No se pudo obtener la geolocalización de Bogotá. Por favor verifique la dirección o intente más tarde.');
          setAddress(''); // Reset the address input
          setpropertyType(''); // Reset the propertyType input            
          setBaths(''); // Reset the baths input
          setRooms(''); // Reset the rooms input
          setArea(''); // Reset the area input
          setStratum(''); // Reset the stratum input
          setGarages(''); // Reset the garages input
          setElevator(''); // Reset the elevator input
          setAge(''); // Reset the age input
          setPolygon(null); // Reset the polygon state
          setZoom(13); // Reset the zoom level
          setShowMarker(false); // Hide the marker when changing the city
          onSubmit(null); // Pass null to the parent component    
          setMarkers([]); // Reset the markers state 

        }     
    } catch (error) {
        console.error('Error fetching geolocation:', error.message || error);
        alert('No se pudo obtener la geolocalización. Por favor verifique la dirección o intente más tarde.');
        setAddress(''); // Reset the address input
        geolocation = null;
    } finally {
        setLoading(false);
    }
  };


  // Function to handle the edit location button
  const handleclickEditLocation = () => {
    setZoom(17); 
    setEditMarker(true); // Enable the marker edit option so draggable is true    
  };

  // Function to handle marker drag end
  const handleMarkerDragEnd = (event) => {    
    const newLatLng = event.target.getLatLng(); 
    // console.log("New marker position:", newLatLng);
    setGeoLocationMap([newLatLng.lat, newLatLng.lng]);        
    setZoom(16); // zoom=16 to show the circle radius at 500m 
    setEditMarker(false); // disable the marker edit option so draggable is false   
    // console.log("geolocationMap from address:", geolocationMap);
  };

  // Function to handle the polygon creation in the map and save it in the state
  const createPolygon = (e) => {
    const { layerType, layer } = e;
    console.log("Event received:", e); // Log the event
    if (layerType === 'polygon') {
      // console.log("Polygon created:", layer.toGeoJSON());
      const polygon = layer.toGeoJSON();
      // console.log("Polygon created:", polygon);      
      // You can handle the polygon creation here, e.g., saving it or using it in further calculations
      console.log("Polygon created:", polygon);
      setPolygon(polygon);

    }
  };

  // Function to handle the layers deleted in the map
  const handleLayersDeleted = (e) => {
    // Check if any layers were deleted
    if (e.layers.getLayers().length > 0) {
      setPolygon(null); // Reset the state when layers are deleted
    }
  };

  // Function to handle the analyze button
  const handlleAnalizar = async (event) => {
    event.preventDefault();    

    const params = {
      
      'selectedCity': selectedCity,
      'propertyType': propertyType,
      'address': address,
      'geolocation': geolocationMap,      
      'polygon': polygon,
      'area': parseFloat(area),
      'baths': parseFloat(baths) || 0, // Ensure it's a number, default to 0 if not provided
      'rooms': parseFloat(rooms) || 0, // Ensure it's a number, default to 0 if not provided      
      'garages': parseFloat(garages) || 0, // Ensure it's a number, default to 0 if not provided
      'stratum': parseFloat(stratum) || 0, // Ensure it's a number, default to 0 if not provided
      'elevator': parseFloat(elevator) || 0, // Ensure it's a number, default to 0 if not provided
      'age': parseFloat(age) || 0, // Ensure it's a number, default to 0 if not provided
    }

    setHideForm(true); // Hide the form when submitting


    // Call the handleGetDataLocationInputForm callback with the data
    onSubmit(params); // Pass any relevant data to the parent component
  };

  
  // Icon for the map multiple markers 
  const customIcon = L.divIcon({
    className: 'bg-blue-500 border-2 rounded-full hover:bg-red-500',    
    iconSize: [15, 15],
  });


  // Function to handle the comparables data for the markers in the map  
  const handleMarkableData = (markersVenta, markersRenta) => {
    console.log("Received Data from Child totales venta:", markersVenta);
    console.log("Received Data from Child Metrocuadrado renta:", markersRenta);

    // Ensure comparablesTotalesVenta is an array
    const safeComparablesTotalesVenta = Array.isArray(markersVenta) ? markersVenta : [];
    const safeComparablesMetrocuadradoArriendo = Array.isArray(markersRenta) ? markersRenta : [];

    // Merge both datasets
    const allComparables = [...safeComparablesTotalesVenta, ...safeComparablesMetrocuadradoArriendo];

    // Map through all comparables to create markers
    const markers = allComparables.map((comparable, index) => ({
      id: index,
      latitud: comparable.Latitud,
      longitud: comparable.Longitud,
      area: comparable['Area (m2)'],
      Precio_m2: comparable['$ MM COP/m2'] || comparable['$ COP/m2'], // Handle both sale and rent data
      tipo: comparable['$ MM COP/m2'] ? 'venta' : 'arriendo', // Determine the type based on which field exists
      estrato: comparable["Estrato"],
      baths: comparable['Baños'],
      antiguedad: comparable['Antiguedad'],
      garajes: comparable['Garajes'],
    }));

    console.log('Generated Markers:', markers);
    setMarkers(markers);
  };

  // UseEffect to handle markers when markersprops received change
  useEffect(() => {
    if (markersVenta || markersRenta) {
      handleMarkableData(markersVenta, markersRenta);
    }
  }, [markersVenta, markersRenta]);

  const handleResetForm = () => {
    // reset the form
    setAddress(''); // Reset the address input
    setpropertyType(''); // Reset the propertyType input
    setBaths(''); // Reset the baths input
    setRooms(''); // Reset the rooms input
    setArea(''); // Reset the area input
    setStratum(''); // Reset the stratum input
    setGarages(''); // Reset the garages input
    setElevator(''); // Reset the elevator input
    setAge(''); // Reset the age input
    setPolygon(null); // Reset the polygon state
    setZoom(13); // Reset the zoom level
    setShowMarker(false); // Hide the marker when changing the city
    setEditMarker(false); // Disable the marker edit option
    onSubmit(null); // Pass null to the parent component
    setMarkers([]);
    setHideForm(false);
    
  };


  
  return (
    <div>

      <div>      

        {/* main container of inputs */}
        <div className="mt-4">

          {/* container of inputs , not edit button and map */}
          <div className='flex justify-start '>
            
            {/* city dropdown menu, and hide if hide is true */}
            {hideForm ? null: 
            (
            <div className="mb-4">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                Seleccione una ciudad
              </label>
              <select
                id="city"
                name="city"
                value={selectedCity.name}
                onChange={handleCityChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 bg-slate-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {cities.map((city) => (
                  <option key={city.name} value={JSON.stringify(city)}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            )}
            
            {/* address input form, and hide if hide is true*/}
            {hideForm ? null: 
            (
            <div className="flex-row mb-4 ml-12">
              <form onSubmit={handleUbicarMapa} className="flex">                
                <div className="">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Escriba una dirección 
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                  <p className="m-1 text-xs">Ej: Calle 28D # 38bis - 50</p>

                </div>
                <div className='ml-8 self-center'>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Ubicar en mapa                  
                  </button>
                </div>
              </form>           
              
              <LoadingComponent msg="Ubicando..." show={loading} onClose={handleClose} /> {/* Show the loading modal when loading */}                
            </div>
            )}
            
            {/* reset button, and hide if hide is false */}
            { hideForm ? 
            (
            <div className='self-center my-4'>
                  <button                    
                    className="inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-900"
                    onClick={handleResetForm}
                  >
                    Reiniciar Busqueda                  
                  </button>
            </div>
            ): null}

          </div>

          {/* edit location button, and hide if hide is true*/}
          {showCenterMarker && !hideForm ? 
          (<div className="mb-4">
            <button
              onClick={handleclickEditLocation}
              className="flex mt-2 mb-4 py-2 px-2 border border-2 shadow-sm text-sm font-medium rounded-md text-slate-500 hover:text-slate-600 hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <MapPinIcon className="text-slate-500 h-5 w-5 mr-2"/>
              Editar ubicacion
            </button>   
          </div>)
          : null
          }

          {/* map */}
          <div className="mb-2">          
            <MapContainer center={geolocationMap} zoom={zoom} style={{ height: "400px", width: "100%" }}>
              <ChangeView center={geolocationMap} />
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
              {showCenterMarker && (
                <Marker 
                  position={geolocationMap} 
                  icon={MapMarkerIcon}
                  draggable={editMarker}
                  eventHandlers={{
                    dragend: handleMarkerDragEnd,
                    
                  }}
                  >
                  <FeatureGroup ref={featureGroupRef}>
                    <EditControl
                      position="topright"
                      onCreated={createPolygon}
                      onDeleted={handleLayersDeleted} 
                      draw={{
                        polygon: turnCreatePolygon, // Enable the polygon draw if turnCreatePolygon is true
                        rectangle: false,
                        circle: false,
                        polyline: false,
                        marker: false,
                        circlemarker: false,
                      }}
                      featureGroup={featureGroupRef.current} // Pass the FeatureGroup ref to EditControl
                    />
                  </FeatureGroup>
                  {/* <Popup>
                    {address}
                  </Popup>                 */}
                </Marker>
              )}
              
              {/* show circle on map if markers are more than 3 and also if polygon*/}
              {markers.length>1 && !polygon ? 
              (
                <Circle
                  center={geolocationMap}
                  radius={500} // radius in meters
                  color="blue"
                  fillColor="blue"
                  fillOpacity={0.05}>
                  <Popup>
                    500m radio
                  </Popup>
                </Circle>              
              ):null
              }
              
              {/* add markers received from the parent component */}
              {markers.map(marker => (
                <Marker key={marker.id} position={[marker.latitud, marker.longitud]} icon={customIcon}>
                  <Popup>
                  Negocio: {marker.tipo }<br />
                  COP: $ {Number(marker.area*marker.Precio_m2).toLocaleString()} <br />                  
                  Area: {marker.area} m² <br />      
                  COP/m2: $ {marker.tipo === 'venta' ? Number(Math.round(marker.Precio_m2)).toLocaleString() : Number(Math.round(marker.Precio_m2)).toLocaleString()}<br />            
                  {marker.baths && <>Baños: {marker.baths} <br /></>}
                  {marker.estrato && <>Estrato: {marker.estrato} <br /></>}
                  {marker.garajes && <>Garajes: {marker.garajes} <br /></>}
                  {marker.antiguedad && <>Antigüedad: {marker.antiguedad} <br /></>}
                </Popup>
                </Marker>
              ))}    
            </MapContainer> 

          </div>

           {/* input form property characteristics */}
          {showCenterMarker ?
          (
          <div className="mb-4">
            <form onSubmit={handlleAnalizar} className="space-y-2">          
              
              <div className="flex justify-around">
                {/* propertyType */}
                <div className="py-4 px-2">
                  <label htmlFor="rooms" className="block text-sm font-medium text-gray-700">
                    Tipo
                  </label>
                  <select
                    name="propertyType"
                    id="propertyType"
                    value={propertyType}
                    onChange={(e) => setpropertyType(e.target.value)}
                    required                    
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="apartamento">apartamento</option>
                    <option value="casa">casa</option>   
                  </select>
                </div>
                
                {/* Area input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="area" className="block text-sm font-medium text-gray-700">
                    Área (m²)
                  </label>
                  <input
                    type="number"
                    name="area"
                    id="area"
                    placeholder=''  
                    autoComplete='off'                  
                    value={area}                    
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0) {
                        setArea(parseFloat(e.target.value));
                      }
                    }}
                    required
                    className="mt-1 pl-2 block w-full py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                </div>

                {/* Number of baths input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="baths" className="block text-sm font-medium text-gray-700">
                    Baños
                  </label>
                  <select
                    name="baths"
                    id="baths"
                    value={baths}
                    onChange={(e) => setBaths(e.target.value)}
                    required
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                {/* Number of rooms input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="rooms" className="block text-sm font-medium text-gray-700">
                    Habitaciones
                  </label>
                  <select
                    name="rooms"
                    id="rooms"
                    value={rooms}
                    onChange={(e) => setRooms(e.target.value)}
                    required
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                {/* Number of garages input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="garages" className="block text-sm font-medium text-gray-700">
                    Garages
                  </label>
                  <select
                    name="garages"
                    id="garages"
                    value={garages}
                    onChange={(e) => setGarages(e.target.value)}
                    required
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                {/* Number of stratum input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="stratum" className="block text-sm font-medium text-gray-700">
                    Estrato
                  </label>
                  <select
                    name="stratum"
                    id="stratum"
                    value={stratum}
                    onChange={(e) => setStratum(e.target.value)}
                    required
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>

                {/* Number of elevators input */}
                <div className="py-4 pl-1 px-2">
                  <label htmlFor="elevator" className="block text-sm font-medium text-gray-700">
                    Ascensores de acceso
                  </label>
                  <select
                    name="elevator"
                    id="elevator"
                    value={elevator}
                    onChange={(e) => setElevator(e.target.value)}
                    required
                    className="mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>

                {/* Age input */}
                <div className="py-4">
                  <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                    Edad (años)
                  </label>
                  <input
                    type="number"
                    name="age"
                    id="age"
                    value={age}
                    placeholder=""
                    autoComplete='off'     
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0) {
                        setAge(value);
                      }
                    }}
                    className="mt-1 pl-2 block w-full py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                </div>
              </div>

              {/* Submit button */}
              <div>
                {!hideForm ? 
                (
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Analizar
                </button>
                ): null}
              </div>
            </form>
            <LoadingComponent msg="Analizando..." show={loading} onClose={handleClose}/> {/* Show the loading modal when loading */}          
          </div>     
          ): null
          }

        </div>
      </div>
    </div>
  );
};
