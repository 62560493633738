import React, { useContext, useEffect, useState,useRef } from "react";
import LocationInputForm from './LocationInputForm';
import AuthContext from "./AuthContext";
import { serviceUse } from "./Functions/fetchreact_webapp_habi_data_jul_2024_membership_restapi";
import PopupMessage from "./PopupMessage";


export default function MercadoViviendaNueva() {

  const [dataFromLocationInputForm, setdataFromLocationInputForm] = useState(null); // State to hold the returned data
  const [comparablesTotalesVenta, setComparablesTotalesVenta] = useState([]);
  const [comparablesMetrocuadradoArriendo, setComparablesMetrocuadradoArriendo] = useState([]);



  // Get the user data from the context
  const { User, Membership, setMembership } = useContext(AuthContext);
  
  const user = {
    userId: User.sub,
    name: User.name,
    email: User.email,
  } 

  //define 'serviceName' and 'serviceCost' for the serviceUse function
  const service={serviceName:'appraisal', serviceCost:10000000};

  
  // Callback function to handle the data returned from LocationInputForm
  const handleGetDataLocationInputForm = (data) => {
    console.log("Data received from child LocationInputForm:", data); 

    // check if user has enough balance to use the service
    console.log('Membership balance:', Membership.balance);
    if (Membership.balance < service.serviceCost) {      
            
      alert('No tiene suficiente saldo para realizar la consulta. Por favor recargue su saldo');

      //setdataFormlocationInputForm to null to reset the form
      setdataFromLocationInputForm(null); // Update the state with the data 
      return;
    }
    else{      
       setdataFromLocationInputForm(data); // Update the state with the data 
    }
  };
     
  
  return (
    <div className="bg-white flex-1 mt-4" >

      <div className="flex justify-start border-b-4 border-slate-100">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Mercado de Vivienda Nueva</h1>
      </div>
    
      <div className="">        
        <LocationInputForm onSubmit={handleGetDataLocationInputForm} turnCreatePolygon={false} markersVenta={comparablesTotalesVenta} markersRenta={comparablesMetrocuadradoArriendo}/>
      </div>

    </div>
  )
}