import AuthContext from "./AuthContext";
import React, { useContext, useEffect, useState } from 'react';
import { getBalance, evaluateMembership, withdraw, serviceUse, deposit, createUser, payMembership } from './Functions/fetchreact_webapp_habi_data_jul_2024_membership_restapi';
import { ShieldCheckIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";


export default function AdministrarCuenta() {

  // Get the current URL
  const currentUrl = window.location.href;

  // Construct the redirect URL
  const redirectUrl = currentUrl.replace('admin', 'payment-result');

  // state variables
  const [hashHex, setHashHex] = useState('');
  const [referencia, setreferencia] = useState(''); 
  const [amount, setAmount] = useState(""); // Default value 

  const { User } = useContext(AuthContext);
  const user = {
    userId: User.sub,
    name: User.name,
    email: User.email,
  } 

  const { Membership, setMembership } = useContext(AuthContext);

  // Function to capitalize the first letter of a string  
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  // Function to handle submit_getBalance
  const handlesubmit_getBalance = async (e) => {
    e.preventDefault();
    try {
      const response = await getBalance(user.userId);
      console.log(response);      
      setMembership(response);

    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_evaluateMembership
  const handlesubmit_evaluateMembership = async (e) => {
    e.preventDefault();
    try {
      const response = await evaluateMembership(user.userId);
      console.log(response);      
      setMembership(response);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_withdraw
  const handlesubmit_withdraw = async (e) => {
    e.preventDefault();
    try {
      const response = await withdraw(user.userId, 25000);
      console.log(response);      
      setMembership(response);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_serviceUse
  const handlesubmit_serviceUse = async (e) => {
    e.preventDefault();
    try {
      const response = await serviceUse(user.userId, 'service', 15000);
      console.log(response);      
      setMembership(response);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_deposit
  const handlesubmit_deposit = async (e) => {
    e.preventDefault();
    try {
      const response = await deposit(user.userId, 10000);
      console.log(response);      
      setMembership(response);
      console.log('membership in admin', Membership);  
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_createUser
  const handlesubmit_createUser = async (e) => {
    e.preventDefault();
    try {
      const response = await createUser(user.userId);
      console.log(response);      
      setMembership(response);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  // Function to handle submit_payMembership
  const handlesubmit_payMembership = async (e) => {
    e.preventDefault();
    try {
      const response = await payMembership(user.userId, 'premium', 100000);
      console.log(response);      
      setMembership(response);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }


  // Function to create the hashHex for the Wompi payment
  const handleHaxHex = async () => {
    // event.preventDefault();

    const today = new Date();
    console.log('today', today);
    //change to ISO string keepinh the time
    const todayString = today.toISOString();

    // Remove thousand separators and convert to integer value in cents
    const amountInCents = parseFloat(amount.replace(/,/g, '')) * 100;    
    console.log('amountInCents', amountInCents);
    const amountInCentsString = amountInCents.toString();   

    // concatenate the string with the amountInCents 
    const referencia = "deposit_"+todayString;
    setreferencia(referencia);

    console.log('referencia', referencia);

    // concatenate "<Referencia><Monto><Moneda><SecretoIntegridad>"
    // const cadenaConcatenada = referencia+amountInCentsString+"COP"+"test_integrity_Y6vxcx54EZcCi72mfHHMSkSWelnpHmAe";  //SPL test
    // const cadenaConcatenada = referencia+amountInCentsString+"COP"+"test_integrity_Zj4egpNMIb9jnfv18f3cRHHj7dPgJ5QJ";  //Habi test
    const cadenaConcatenada = referencia+amountInCentsString+"COP"+"prod_integrity_JM6GKNmxBs1zFdTnxuzRq23n01tiajlX";  //Habi prod

    console.log('cadenaConcatenada', cadenaConcatenada);
    const encondedText = new TextEncoder().encode(cadenaConcatenada);
    const hashBuffer = await crypto.subtle.digest("SHA-256", encondedText);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex_var = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");    
    setHashHex(hashHex_var);
    console.log('final hashHex', hashHex);
  };

  // useEffect to update the hashHex when the amount changes
  useEffect(() => {
    if (amount !== "") {
      handleHaxHex();
    }
  }, [amount]);

  // useEffect(() => {
  //   if (referencia && hashHex) {
  //     // Construct URL parameters
  //     const amountInCents = parseFloat(amount.replace(/,/g, '')) * 100;
  //     const params = new URLSearchParams({
  //       'public-key': 'pub_test_iJpw5Gu1Od3NBsjox2x6FJGqJhgvyTWQ',
  //       'currency': 'COP',
  //       'amount-in-cents': amountInCents,
  //       'reference': referencia,
  //       'signature:integrity': hashHex,
  //       'redirect-url': 'http://localhost:3000/payment-result',
  //     });

  //   }
  // }, [referencia, hashHex]);

  
  // Function to format the number
  const formatNumber = (value) => {
    const cleanValue = value.replace(/[^\d.]/g, '');
    let parts = cleanValue.split('.');

    // Ensure only two decimal places
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2);
    }

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  };

  // Function to handle the amount change
  const handleAmountChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    setAmount(formattedValue);
  };

  // Function to handle the form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();        

    // Construct URL parameters
    const amountInCents = (parseFloat(amount.replace(/,/g, '')) * 100).toString();  
    const params = new URLSearchParams({
      // 'public-key': 'pub_test_iJpw5Gu1Od3NBsjox2x6FJGqJhgvyTWQ', //SPL test
      // 'public-key': 'pub_test_67lRhle31X5PKE3AiznqbDLhJBWbYZol', // Habi test
      'public-key': 'pub_prod_B2SCMLj3ouMszvH46WxnByDuzf28gAth', // Habi prod
      'currency': 'COP',
      'amount-in-cents': amountInCents,
      'reference': referencia,
      'signature:integrity': hashHex,      
      'redirect-url': redirectUrl, // Habi
  });

  // Redirect to Wompi checkout page
  window.location.href = `https://checkout.wompi.co/p/?${params.toString()}`;
  };

  // Function to format the date and show it in the Colombian time zone
  const formatDate = (dateString) => {
    // Validate if dateString exists and is a valid date
    if (!dateString || isNaN(Date.parse(dateString))) {
      return 'N/A'; // or return 'Invalid Date' if you prefer to show a message
    }
  
    // Convert the date string to a Date object
    const date = new Date(dateString);
  
    // Calculate the time in the Colombian time zone (UTC-5)
    const colombianTime = new Date(date.toLocaleString('en-US', { timeZone: 'America/Bogota' }));
  
    // Extract the needed parts
    const hours = colombianTime.getHours().toString().padStart(2, '0');
    const minutes = colombianTime.getMinutes().toString().padStart(2, '0');
    const day = colombianTime.getDate();
    const month = colombianTime.toLocaleString('default', { month: 'short' }); // Oct
    const year = colombianTime.getFullYear();
  
    // Return the formatted string in the desired order
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

    
  return (
    
    <div className="flex-columns">

      {/* Account information */}
      <div>
        <div className="pt-8 px-4 sm:px-0">
          <h3 className="text-base font-bold leading-7 text-gray-900">Administrar mi cuenta</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
        </div>
        <div className="mt-6 border-t border-b border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Tipo de usuario: </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Persona Natural</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Nombre del Usuario: </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{capitalizeFirstLetter(User.name)}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Correo: </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{User.email}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Membresia actual:</dt>
              <dd>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{capitalizeFirstLetter(Membership.membership_type)}</dd>                                
                <div className="mt-4">
                  <Link to="/termsosuse" className="text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0 hover:text-indigo-600">
                  Terminos y Condiciones
                  </Link>
                </div>

              </dd>
            </div>               
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Vencimiento membresia actual: </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatDate(Membership.membership_expiration)}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Saldo en cuenta:</dt>
              <dd className="mt-1 text-sm font-bold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$ {Number(Membership.balance).toLocaleString()}</dd>
            </div>           
          </dl>
        </div>
      </div>

      {/* wompi form */}
      <div className="mt-8">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ">
          <dt className="text-sm font-medium leading-6 text-gray-900">Indique el valor para cargar en su cuenta: </dt>
          <form onSubmit={handleFormSubmit} className="">
          {/* <!-- OBLIGATORIOS --> */}
          <input type="hidden" name="public-key" value="pub_prod_B2SCMLj3ouMszvH46WxnByDuzf28gAth" />
          <input type="hidden" name="currency" value="COP" />
          <input
            id="price"
            name="amount-in-cents"
            type="text"
            autoComplete="off"
            value={amount}
            onChange={handleAmountChange}
            placeholder="0.00"
            required
            className="block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <input type="hidden" name="reference" value={referencia} />
          <input type="hidden" name="signature:integrity" value={hashHex} />
          <input type="hidden" name="redirect-url" value={redirectUrl} />          

          {/* <!-- OPCIONALES --> */}

          <button className="flex items-center mt-4 px-4 py-2 bg-blue-900 text-white rounded">
            <ShieldCheckIcon className="h-5 w-5 mr-2" />
            Recarga con Wompi
          </button>
        </form>          
        </div> 
      </div>         
    </div>
  );

   
  
}