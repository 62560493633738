import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import AuthContext from "./AuthContext";
import { useContext } from "react";

export default function LayoutDashboard () {        
    console.log("layout print");

    // get the user data from the context
    const context = useContext(AuthContext);
    if (!context || !context.User) {
        console.error("AuthContext or User is null");
        return <div>Error: AuthContext or User is not available</div>;
    }

    // get the user data
    const { User } = context;
    const user = {
      name: User.name,
      email: User.email,
    };
    console.log("user.name: " + user.name);

    // get the current year
    const year = new Date().getFullYear();
    
    return(
        <>
        <div className="flex">
          <SideBar/>
          <Outlet/> 
        </div>   
        <div className="pt-4 bg-white mb-10 text-center text-xs border-t border-gray-200  bottom-0 left-0 right-0 font-sans">
            <span>© Copyright {year} habi</span>                    
          </div>       
        </>
    );
}