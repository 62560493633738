
// fetch__restapi.jsx

// URL of your API Gateway
const API_BASE_URL = 'https://k3c3ecw9hd.execute-api.us-east-1.amazonaws.com/prod/netsuite';


// '''
//     customer:
//         - type : 
//             1: natural
//             2: juridica
        
//         - typeDocument:
//             11 - Registró civil de Nacimiento
//             12 - Tarjeta de Identidad
//             13 - Cédula de Ciudadanía
//             21 - Tarjeta de extranjería
//             22 - Cédula de extranjería
//             31 - NIT
//             41 - Pasaporte
//             42 - Documento de i...ficación extranjero
//             47 - PEP
//             48 - PPT (Permiso Protección Temporal)
//             91 - NUIP
//             para persona juridica son:}
//             31 - NIT
//             42 - Documento de i...ficación extranjero
//             50 - NIT de otro país
//             91 - NUIP
    
//     '''



// Function to make API calls
export const toBill = async (data) => {

  try {
    const response = await fetch(API_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data        
      }),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log('API call response:', responseData);
    return responseData;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};



