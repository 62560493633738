// fetchAppraisal.js


// Function to fetch the lotcodigo of a given address in Bogota
export const GeocordinatesBog = async (address) => {
  const url = 'https://catalogopmb.catastrobogota.gov.co/PMBWeb/web/api';
  const params = new URLSearchParams({
    cmd: 'geocodificar',
    apikey: 'e2d6f043-7b63-417e-8fbe-db515898576f',
    query: address
  });

  const headers = {
    'Accept': 'application/json, text/javascript, */*; q=0.01',
    'Accept-Language': 'en-US,en;q=0.9,es;q=0.8',
    'Connection': 'keep-alive',
    'Origin': 'https://mapas.bogota.gov.co',
    'Referer': 'https://mapas.bogota.gov.co/',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'cross-site',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
    'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"'
  };

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    const data = await response.json();
    console.log('data:', data);
    const latitud = parseFloat(data.response.data.latitude);
    console.log('latitud:', latitud);
    const longitud = parseFloat(data.response.data.longitude);
    let geolocation=[latitud, longitud];
    console.log('geolocation bgta current:', geolocation);

    return geolocation;
  } catch (error) {
    console.error('Error fetching geolocation:', error);
    return null;
  }
};

// Function to fetch the lotcodigo of a given address in medellin
export const GeocordinatesMed = async (address) => {
  const url = 'https://www.medellin.gov.co/servicios5/GEOCOD_WEB_MAPGIS9/geocodificador/service/geocod';
  const params = new URLSearchParams({
    accion: '11111111111',
    dir: address
  });

  const headers = {
    'Accept': '*/*',
    'Accept-Language': 'en-US,en;q=0.9,es;q=0.8',
    'Connection': 'keep-alive',
    'Cookie': 'cookiesession1=678B28684450406E03C9F251BBC203C8; visita=true; SESSIONmapgis=1224373E509184AECB1A9C6B0EDD7948; SESSIONmapgis_seg=E3DFFBB1B3BDC3A351151BC610208751; _ga_QWS8695FB6=GS1.1.1721605214.1.1.1721605226.48.0.0; saplb_*=(J2EE2718120)2718150; _gid=GA1.3.1535786050.1721605228; SESSIONBANCOIMAGEN=A408E0C0F12CCB645E486303E49776B5; _ga_HR611VRDM1=GS1.1.1721605228.1.0.1721605228.0.0.0; _ga_ZNL5NBVD6G=GS1.1.1721605228.1.0.1721605228.0.0.0; _ga=GA1.1.763302245.1721605215',
    'Referer': 'https://www.medellin.gov.co/mapgis9/mapa.jsp?aplicacion=1&css=css/app_mapas_medellin.css',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'same-origin',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
    // 'X-Requested-With': 'XMLHttpRequest',
    'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"'
  };

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    const data = await response.json();
    // console.log('data med :', data[0]); 
    let geolocation=[data[0].p13, data[0].p12];
    console.log('geolocation_med:', geolocation);
    return geolocation;
  } catch (error) {
    console.error('Error fetching geolocation:', error);
    return null;
  }
};


// Function to fetch the geolocation of any address in Colombia
export const GeocordinatesCol = async (address, selectedCity) => {
  const url = 'https://apiv2.habi.co/habi-georeferencing-api/api_georeferenciacion';
  
  console.log('GeocordinatesCol:', selectedCity, address);

  const headers = {
    'accept': 'application/json, text/plain, */*',
    'accept-language': 'en-US,en;q=0.9,es;q=0.8',
    'content-type': 'application/json',
    'origin': 'https://habi.co',
    // 'priority': 'u=1, i',
    'referer': 'https://habi.co/',
    // 'sec-ch-ua': '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
    // 'sec-ch-ua-mobile': '?0',
    // 'sec-ch-ua-platform': '"Windows"',
    // 'sec-fetch-dest': 'empty',
    // 'sec-fetch-mode': 'cors',
    // 'sec-fetch-site': 'same-site',
    // 'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36',
    'x-api-key': 'MTBxKtN7bk16ETfwX9NgM35CemZCKBGY5g6ECUiQ'
  };

  const payload = {
    "direccion": address,
    "ciudad": selectedCity,
    "pais": "CO",
    "sugerencias": true,
    "tipoInmueble": 1,
    "open_address_input": true
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`Error fetching geolocation: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('data:', data);

    // Extract latitude and longitude from the response data if available
    const latitud = parseFloat(data.latitude || data.latitud);
    const longitud = parseFloat(data.longitude || data.longitud);
    let geolocation = [latitud, longitud];
    console.log('Geolocation:', geolocation);

    return geolocation;
  } catch (error) {
    console.error('Error fetching geolocation:', error);
    return null;
  }
};



// function to make the api call to api gateway and lambda habimetro function
export const HabimetroApi = async ({geolocation, propertyType, baths, age, area, stratum, garages, elevator}) => {
  
  const url = `https://sfn1p9qt3d.execute-api.us-east-1.amazonaws.com/prod/hb`;

  const params = {    
    'inmueble_idd': "None",
    'vetustez': 4,
    'geolocation': JSON.stringify(geolocation),  // Convert array to string
    'tipo_inmueble': propertyType,
    'area': parseFloat(area),
    'elevator': parseFloat(elevator) || 0, // Ensure it's a number, default to 0 if not provided
    'garages': parseFloat(garages) || 0, // Ensure it's a number, default to 0 if not provided
    'baths': parseFloat(baths) || 0, // Ensure it's a number, default to 0 if not provided
    'stratum': parseFloat(stratum) || 0, // Ensure it's a number, default to 0 if not provided
    'age': parseFloat(age) || 0, // Ensure it's a number, default to 0 if not provided
    'pid': "None",
    'negocio_id': "None",
    'lote_id': "None"
  };

  // Construct the query string
  const queryStringHabimetro = new URLSearchParams(params).toString();
 
  const fullUrl = `${url}?${queryStringHabimetro}`;

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const content = await response.json();
    console.log('Hb API response:', content);
    return content;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};


// function to make the api call to api gateway and lambda metrocuadrado function
export const Metrocuadrado = async ({geolocation, propertyType, baths, age, area, stratum, garages, elevator}) => {
  
  const url = `https://sfn1p9qt3d.execute-api.us-east-1.amazonaws.com/prod/list`;

  const params = {        
    'geolocation': JSON.stringify(geolocation),  // Convert array to string
    'tipo_inmueble': propertyType,
    'area': parseFloat(area),
    'elevator': parseFloat(elevator) || 0, // Ensure it's a number, default to 0 if not provided
    'garages': parseFloat(garages) || 0, // Ensure it's a number, default to 0 if not provided
    'baths': parseFloat(baths) || 0, // Ensure it's a number, default to 0 if not provided
    'stratum': parseFloat(stratum) || 0, // Ensure it's a number, default to 0 if not provided
    'age': parseFloat(age) || 0, // Ensure it's a number, default to 0 if not provided  
  };

  // Construct the query string
  const queryString = new URLSearchParams(params).toString();
 
  const fullUrl = `${url}?${queryString}`;

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const content = await response.json();
    console.log('Listings API response:', content);
    return content;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};


// function to orchestrate the fetching of geolocation based on the city selected
export const fetchAppraisal_function = async ({dataFromLocationInputForm}) => {
  console.log('data received from parent:', dataFromLocationInputForm);

  let habimetro_api_data = null;
  let metrocuadrado_api_data = null;
  let result = null;

  // Function to try API calls twice
  const tryApiCallTwice = async (apiFunction, inputData) => {
    let attempt = 0;
    while (attempt < 3) {
      try {
        return await apiFunction(inputData);
      } catch (error) {
        console.error(`Error fetching data on attempt ${attempt + 1}:`, error);
        attempt += 1;
      }
    }
    return null;
  };

  // try habimetro api call twice
  habimetro_api_data = await tryApiCallTwice(HabimetroApi, dataFromLocationInputForm);

  // try metrocuadrado api call twice
  metrocuadrado_api_data = await tryApiCallTwice(Metrocuadrado, dataFromLocationInputForm);

  result = {
    "habimetro": habimetro_api_data ? {
      "params": habimetro_api_data.params,
      "geolocation": habimetro_api_data.geolocation,
      "estimated_value": habimetro_api_data.estimated_value,
      "estimated_value_upper_bound": habimetro_api_data.estimated_value_upper_bound,
      "estimated_value_lower_bound": habimetro_api_data.estimated_value_lower_bound,
      "comparables": habimetro_api_data.comparables,
    } : null,
    "metrocuadrado": metrocuadrado_api_data ? {
      "params": metrocuadrado_api_data.params,
      "geolocation": metrocuadrado_api_data.geolocation,
      "avg_price_m2_last_5y_yearly": metrocuadrado_api_data.avg_price_m2_last_5y_yearly,
      "avg_prices_listing_metrocuadrado_by_antiguedad_last_5y_monthly": metrocuadrado_api_data.avg_prices_listing_metrocuadrado_by_antiguedad_last_5y_monthly,
      "avg_prices_listing_metrocuadrado_by_area_last_5y_monthly": metrocuadrado_api_data.avg_prices_listing_metrocuadrado_by_area_last_5y_monthly,
      "caprates": metrocuadrado_api_data.caprates,
      "listing_total_area_last_5y_monthly": metrocuadrado_api_data.listing_total_area_last_5y_monthly,
      "share_lisitngs_total_last_5y_total": metrocuadrado_api_data.share_lisitngs_total_last_5y_total,
      "units_listing_antiguedad_last_5y_monthly": metrocuadrado_api_data.units_listing_antiguedad_last_5y_monthly,
      "comparables": metrocuadrado_api_data.comparables,
    } : null
  };

  return result;
};



// function to make the api call to api gateway and lambda snr function
export const fetchSnr = async ({dataFromLocationInputForm}) => {

  console.log('data received from parent:', dataFromLocationInputForm);
  
  const url = `https://sfn1p9qt3d.execute-api.us-east-1.amazonaws.com/prod/snr`;

  
  const params = {        
    'selectedCity':dataFromLocationInputForm.selectedCity,
    'geolocation': JSON.stringify(dataFromLocationInputForm.geolocation),  // Convert array to string
    'tipo_inmueble': dataFromLocationInputForm.propertyType,
    'area': parseFloat(dataFromLocationInputForm.area),
    'elevator': parseFloat(dataFromLocationInputForm.elevator) || 0, // Ensure it's a number, default to 0 if not provided
    'garages': parseFloat(dataFromLocationInputForm.garages) || 0, // Ensure it's a number, default to 0 if not provided
    'baths': parseFloat(dataFromLocationInputForm.baths) || 0, // Ensure it's a number, default to 0 if not provided
    'stratum': parseFloat(dataFromLocationInputForm.stratum) || 0, // Ensure it's a number, default to 0 if not provided
    'age': parseFloat(dataFromLocationInputForm.age) || 0, // Ensure it's a number, default to 0 if not provided
    'polygon': JSON.stringify(dataFromLocationInputForm.polygon) || 0, // Ensure it's a number, default to 0 if not provided
  };

  // Construct the query string
  const queryString = new URLSearchParams(params).toString();

  console.log('queryString:', queryString);
 
  const fullUrl = `${url}?${queryString}`;

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const content = await response.json();
    console.log('fetchSnr API response:', content);
    return content;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};