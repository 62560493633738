
// fetch_react_webapp_habi_data_jul_2024_membership_restapi.jsx

// URL of your API Gateway
const API_BASE_URL = 'https://k3c3ecw9hd.execute-api.us-east-1.amazonaws.com/prod/webapp';

// Function to make API calls
const apiCall = async (action, data) => {
  try {
    const response = await fetch(API_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
        action,
      }),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

// Function to get balance
export const getBalance = async (userId) => {
  return await apiCall('get_balance', { userId });
};

// Function to evaluate membership
export const evaluateMembership = async (userId, name, email) => {
  return await apiCall('evaluate_membership', { userId, name, email });
};

// Function to withdraw money
export const withdraw = async (userId, amount) => {
  return await apiCall('withdraw', { userId, amount });
};

// Function to use a service
export const serviceUse = async (userId, name, email, serviceName, serviceCost) => {
  return await apiCall('use_service', { userId, name, email, serviceName, serviceCost });
};

// Function to deposit money
export const deposit = async (userId, name, email, amount) => {
  console.log('starting deposit');
  console.log('userId:', userId);
  console.log('amount:', amount);
  
  return await apiCall('deposit', { userId, name, email, amount });
};

// Function to create a free user
export const createUser = async (userId, name, email) => {
  return await apiCall('create_user', { userId, name, email });
};

// Function to pay for membership
export const payMembership = async (userId, membershipType, membershipCost) => {
  return await apiCall('pay_membership', { userId, membershipType, membershipCost });
};
