import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes";

import { AuthProvider } from "./components/AuthContext";
import LayoutDashboard from "./components/LayoutDashboard";


import HomeDashboardPage from "./pages/HomeDashboardPage";
import MercadoViviendaNuevaPage from "./pages/MercadoViviendaNuevaPage";
import AppraisalPage from "./pages/AppraisalPage";
import TransaccionesPage from "./pages/TransaccionesPage";
import AdministracionCuentaPage from "./pages/AdministracionCuentaPage";
import TerminosyCondiciones from "./pages/TerminosyCondiciones";

import InfoCatastro from "./components/InfoCatastro";
import PaymentResult from "./components/PaymentResult";


function App() {

  return (
    
    // context variables to be share across all components
    <AuthProvider>      
        <BrowserRouter>      
          <Routes>           
              <Route path="/" element={<LoginPage/>}/>                             
              <Route path="/registrarse" element={<RegistrationPage/>}/>  
              <Route path="/recuperarconstraseña" element={<ForgottenPasswordPage/>}/>    

              {/* PrivateRouter conditional render all children components using outlet component defined in react-router v6 */}
              <Route element={<PrivateRoutes/>}>
                {/* Use LayoutDashboard component to render child components; using outlet property */}
                <Route element={<LayoutDashboard/>}>
                  <Route path="/dashboard" element={<HomeDashboardPage/>}/>
                  <Route path="/infoCatastro" element={<InfoCatastro/>}/>
                  <Route path="/transacciones" element={<TransaccionesPage/>}/>
                  <Route path="/proyectos" element={<MercadoViviendaNuevaPage/>}/>
                  <Route path="/appraisal" element={<AppraisalPage/>}/>
                  <Route path="/admin" element={<AdministracionCuentaPage/>}/>
                  <Route path="/termsosuse" element={<TerminosyCondiciones/>}/>
                  <Route path="/payment-result" element={<PaymentResult/>}/>
                </Route>
              </Route>   
            
          </Routes>
        </BrowserRouter>
      
      </AuthProvider>
      
    
  );
}

export default App;


