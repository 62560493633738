
import MercadoViviendaNueva from '../components/MercadoViviendaNueva';

export default function MercadoViviendaNuevaPage() {

  
  return (
    <div className="bg-white mx-auto max-w-9xl py-4 sm:px-6 lg:px-8 flex-1">      
        <MercadoViviendaNueva/>  
    </div>

  );
}