import ForgottenPassword from "../components/Login/ForgottenPassword";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";


export default function ForgottenPasswordPage() {  

  return (
    <>
    <div className="flex place-content-center min-h-screen relative isolate overflow-hidden bg-gray-900 py-20 md:pb-20">
      <img
        src="https://habi.co/static/db768f9fc78a0f5dc6838e8ff59bfdf6/c4473/Banner_1400x480_f7dae8ae6d.webp"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-right contrast-50 z-1"
      />
      <div
        className="hidden sm:absolute sm:top-0 sm:left-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl  "
        aria-hidden="true"
      >        
      </div>     

      <div className="grid grid-cols-1 gap-14 grid-rows-1 justify-items-end items-center md:grid-cols-2 ">       
        <div className="">
            <div className="rounded-lg border-1 overflow-hidden shadow-2xl border-2 border-slate-800">     
              <ForgottenPassword/>                                                             
            </div>
        </div>        
        <div className="invisible md:visible"> 
          <Link to="/">
            <div className="flex items-center bg-white/70 border-2 border-slate-800 rounded-full">
              <img
                className="p-4 h-8 w-auto md:h-20 drop-shadow-2xl"
                src={logo}
                alt="Habi"
              />
              <div className='mx-2 font-semibold font-sans text-3xl pr-4 text-black tracking-wide'>data.habi</div>            
            </div> 
          </Link>        
        </div>      
      </div>        
    </div>        
    </>
  );
}
  