import React, { createContext, useState, useContext, useEffect } from 'react';
import { isTokenExpired, refreshToken } from './authUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  // state variables
  const [User, setUser] = useState(null);
  const [Membership, setMembership] = useState(null);
  const [loading, setLoading] = useState(true);  
 
  // Check if there is a user in local storage and set it in the state
  useEffect(() => {

    const loadUserData = async () => {
    // Check if there is a user in local storage
    const storedUser = localStorage.getItem('User');
    console.log("storedUser: " + storedUser);

    // check if there is a membership in local storage
    const storedMembership = localStorage.getItem('Membership');
    console.log("storedMembership: " + storedMembership);

    if (storedUser && storedMembership) {
      setUser(JSON.parse(storedUser));
      setMembership(JSON.parse(storedMembership));
    }
    setLoading(false);
  };
  loadUserData();
  }, []);

  // Save the user in local storage
  useEffect(() => {
    if (User) {
      localStorage.setItem('User', JSON.stringify(User));
      console.log("User: " + User.name + " is logged");
    } else {
      localStorage.removeItem('User');
    }
  }, [User]);

  // Save the membership in local storage
  useEffect(() => {
    if (Membership) {
      localStorage.setItem('Membership', JSON.stringify(Membership));
    } else {
      localStorage.removeItem('Membership');
    }
  }, [Membership]);


  // Check if the token is expired and refresh it
  useEffect(() => {
    const checkToken = async () => {
      const storedUser = JSON.parse(localStorage.getItem('User'));
      const storedMembership = JSON.parse(localStorage.getItem('Membership'));
      
      if (storedUser) {
        if (isTokenExpired(storedUser.token)) {
          try {
            const newToken = await refreshToken(storedUser.refreshToken);
            storedUser.token = newToken;
            localStorage.setItem('User', JSON.stringify(storedUser));
          } catch (error) {
            console.error('Token refresh failed:', error);
            setUser(null);
            setMembership(null);
            localStorage.removeItem('User');
            localStorage.removeItem('Membership');
          }
        }
        setUser(storedUser);
        setMembership(storedMembership);
      }
    };
    
    checkToken();
  }, []);
  
  // Show loading screen while checking token
  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <AuthContext.Provider value={{ User, setUser, Membership, setMembership }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
