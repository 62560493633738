// authUtils.js

import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
  };
  
  export const refreshToken = async (refreshToken) => {
    // Implement your refresh token logic here
    // For example, making an API call to refresh the token
    const response = await fetch('/api/refresh-token', {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: { 'Content-Type': 'application/json' },
    });
  
    if (response.ok) {
      const { newToken } = await response.json();
      return newToken;
    } else {
      throw new Error('Failed to refresh token');
    }
  };
  