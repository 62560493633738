
import Instructivo from "../components/Instructivo"

export default function HomeDashboardPage() {
     
  
  return (
    <div className="bg-white mx-auto max-w-9xl py-4 sm:px-6 lg:px-8" >
      <div className="mx-auto max-w-2xl px-4 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
        <Instructivo/>        
      </div>

    </div>
  )
}