import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { deposit } from './Functions/fetchreact_webapp_habi_data_jul_2024_membership_restapi';
import LoadingComponent from './LoadingComponent'; 
import { Dialog } from '@headlessui/react';
import PopupMessage from './PopupMessage';
import {toBill} from './Functions/toBill';


const PaymentResult = () => {

  // states variables
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, settransactionData] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const[showPopup, setShowPopup] = useState(false);
  const[showLoading, setShowLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [transactionAmount, setTransactionAmount] = useState(null);
  const [open, setOpen] = useState(true);
  const [customerData, setCustomerData] = useState({
    type: '',
    typeDocument: '',
    document: '',
    firstName: '',
    companyName: '',
    secondName: '',
    lastName: '',
    secondLastName: '',    
    correo: '',
    direccion: '',
    pais: '',
    telefono: '',
    regimeniva: '',
    renta: '',
    ica: '',
    reteica: '',
    actividadeconomica: '',
    ciiu: '',
  });
  
  const { User,Membership,setMembership } = useContext(AuthContext);
  const user = {
    userId: User.sub,
    name: User.name,
    email: User.email,
  } 
  console.log('User id:', user.userId);

  // check if the user is logged
  useEffect(() => {
    if (!User) {
      navigate('/login');
      return;
    }

    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    console.log('Payment result id:', id);
    setTransactionId(id);
  }, [location.search, navigate, User]);

  // verify the transaction status
  useEffect(() => {
    const verifyTransaction = async () => {
      if (transactionId) {
        try {
          // Verify the transaction status with the payment platform's API
          // const response = await fetch(`https://sandbox.wompi.co/v1/transactions/${transactionId}`); //sandbox api
          const response = await fetch(`https://production.wompi.co/v1/transactions/${transactionId}`); //production api
          const data = await response.json();
          console.log('Transaction status:', data);
          settransactionData(data);
          setTransactionStatus(data.data.status);
          setTransactionAmount(data.data.amount_in_cents / 100);  // Convert from cents to dollars
          console.log('Transaction amount:', data.data.amount_in_cents / 100);
          if (data.data.status === 'APPROVED') {
            try {
              const depositResponse = await deposit(user.userId, user.name, user.email, data.data.amount_in_cents / 100);
              console.log(depositResponse);
              setMembership(depositResponse);
              console.log('membership in admin', Membership);
              
            } catch (error) {
              console.error('Error creating user:', error);
            }
          }
        } catch (error) {
          console.error('Error verifying transaction:', error);
          setTimeout(() => {
            navigate('/admin');
          }, 1000);  

        }
      }
    };
  
    verifyTransaction();
        
    }, [transactionId, User]);



    const handleSubmitBill = async (data) => {
      data.preventDefault();
      
      // consolidating the data to be sent to bill
      const mergedData = {
        ...transactionData,
          data: {                           
        ...transactionData.data,         // Spread existing data inside 'data'
        customer: customerData, 
        user_name: User.name,
        user_email: User.email,
        userId: User.sub,
       }                                     
      };
      // console.log('merged data:', mergedData);
  
      // request to bill
      try {
        const billResponse = await toBill(mergedData);
        console.log('bill response:', billResponse);        
        // wait 3 seconds and redirect to '/admin' after the transaction is approved
        setOpen(false);
        setShowPopup(true);
        setTimeout(() => {
          navigate('/admin');
        }, 4000);          
        
      }
      catch (error) {
        console.error('Error sending data to bill:', error);
      }       
  
      
    };  

  return (
    <>
    <div className='flex m-8 align-center'>
      {transactionStatus === 'APPROVED' ?  (
        <Dialog 
          open={open} 
          onClose={() => {}} // Disable closing by click outside
          className="fixed inset-0 z-[9999] flex items-end justify-center p-4 sm:items-center sm:p-0"
        >
          {/* Optional overlay to darken the background */}
          <Dialog.Overlay 
              className="fixed inset-0 bg-gray-500 bg-opacity-75"
              style={{ zIndex: 9998 }} // Ensure the overlay is beneath the popup
            />
            <Dialog.Panel 
              className="relative bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              style={{ 
                zIndex: 9999,  // Ensure the panel is above everything
                maxHeight: '80vh',  // Maximum height for the dialog
                overflowY: 'auto'  // Enable scrolling if content exceeds the height
              }}
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">              
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base text-center font-semibold leading-6 text-white bg-indigo-600">
                      Ingrese sus datos para la factura
                    </Dialog.Title>
                    <div className="mt-2">
                      <form onSubmit={handleSubmitBill}>                    
                        {/* input type */}          
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="type" className="block text-sm font-medium text-gray-700 self-center">
                            Tipo de persona
                          </label>
                          <select
                              name="type"
                              id="type"
                              value={customerData.type}
                              onChange={(e) => setCustomerData({ ...customerData, type: parseInt(e.target.value) })}
                              required
                              className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value=""></option>
                              <option value="1">Natural</option>
                              {/* <option value="2">Juridica</option>                     */}
                            </select>
                          </div>
                        {/* input typeDocument */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="typeDocument" className="block text-sm font-medium text-gray-700 self-center">
                            Tipo de documento
                          </label>
                          <select
                              name="typeDocument"
                              id="typeDocument"
                              value={customerData.typeDocument}
                              onChange={(e) => setCustomerData({ ...customerData, typeDocument: parseInt(e.target.value) })}
                              required
                              className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value=""></option>
                              {customerData.type===1? 
                              (
                                <>
                                  <option value="13">Cedula</option>              
                                  <option value="22">Cedula extranjeria</option>      
                                  <option value="21">Tarjeta de extranjería</option>              
                                  <option value="41">Pasaporte</option>
                                  <option value="12">Tarjeta de Identidad</option>    
                                  <option value="42">Documento de identificación extranjero</option>  
                                  <option value="47">PEP</option>    
                                  <option value="48">PPT (Permiso Protección Temporal)</option>    
                                  <option value="99">NUIP</option> 
                                  <option value="11">Registró civil de Nacimiento</option>        
                                </>
                              ) : null}                    
                              {customerData.type===2? 
                              (
                                <>
                                  <option value="31">NIT</option>
                                  <option value="50">NIT de otro país</option>
                                </>
                              )
                              :null}
                            </select>
                          </div>
                        {/* input document */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="document" className="block text-sm font-medium text-gray-700 self-center">
                            Numero de Documento 
                          </label>
                          <input
                            type="text"
                            name="document"
                            id="document"
                            value={customerData.document}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Only allow numeric input (including backspace)
                              if (/^\d*$/.test(value)) {
                                setCustomerData({ ...customerData, document: value });
                              }
                            }}
                            required
                            minLength={5} // Ensures at least 5 characters
                            className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"              
                          />
                        </div>
                        {/* input firstName */}
                        {customerData.type===1?
                          (
                            <>
                              <div className="py-4 pl-1 px-2 flex">
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 self-center">
                                  Primer nombre
                                </label>
                                <input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  value={customerData.firstName}
                                  onChange={(e) => setCustomerData({ ...customerData, firstName: e.target.value })}
                                  required
                                  minLength={3} // Ensures at least 3 characters
                                  className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                />
                              </div>
                            </>
                          ):null}            

                        {/* input companyName */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">          
                              <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 self-center">
                                Razon social
                              </label>
                              <input
                                type="text"
                                name="companyName"
                                id="companyName"
                                value={customerData.companyName}
                                onChange={(e) => setCustomerData({ ...customerData, companyName: e.target.value })}                  
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}         
                          


                        {/* input secondName */}
                        {customerData.type===1?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">          
                              <label htmlFor="secondName" className="block text-sm font-medium text-gray-700 self-center">
                                Segundo nombre
                              </label>
                              <input
                                type="text"
                                name="secondName"
                                id="secondName"
                                value={customerData.secondName}
                                onChange={(e) => setCustomerData({ ...customerData, secondName: e.target.value })}                  
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}
                          
                        
                        {/* input first lastName */}
                        {customerData.type===1?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 self-center">
                                Primer apellido
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={customerData.lastName}
                                onChange={(e) => setCustomerData({ ...customerData, lastName: e.target.value })}
                                minLength={3} // Ensures at least 3 characters
                                required
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        {/* input Segundo apellido */}
                        {customerData.type===1?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="sec" className="block text-sm font-medium text-gray-700 self-center">
                                Segundo apellido
                              </label>
                              <input
                                type="text"
                                name="secondLastName"
                                id="secondLastName"
                                value={customerData.secondLastName}
                                onChange={(e) => setCustomerData({ ...customerData, secondLastName: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        {/* input correo */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 self-center">
                            Correo
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={customerData.correo}
                            onChange={(e) => setCustomerData({ ...customerData, correo: e.target.value })}
                            required
                            className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        </div>
                        {/* input direccion */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="direccion" className="block text-sm font-medium text-gray-700 self-center">
                            Direccion
                          </label>
                          <input
                            type="text"
                            name="direccion"
                            id="direccion"
                            value={customerData.direccion}
                            onChange={(e) => setCustomerData({ ...customerData, direccion: e.target.value })}
                            minLength={5} // Ensures at least 5 characters
                            required
                            className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        </div>
                        {/* input pais */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="pais" className="block text-sm font-medium text-gray-700 self-center">
                            Pais
                          </label>
                          <input
                            type="text"
                            name="pais"
                            id="pais"
                            value={customerData.pais}
                            onChange={(e) => setCustomerData({ ...customerData, pais: e.target.value })}
                            minLength={3} // Ensures at least 3 characters
                            required
                            className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        </div>
                        {/* input telefono */}
                        <div className="py-4 pl-1 px-2 flex">
                          <label htmlFor="telefono" className="block text-sm font-medium text-gray-700 self-center">
                            Telefono (incluya indicativo del pais)
                          </label>
                          <input
                            type="text"
                            name="telefono"
                            id="telefono"
                            value={customerData.telefono}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Only allow numeric input (including backspace)
                              if (/^\d*$/.test(value)) {
                                setCustomerData({ ...customerData, telefono: e.target.value  });
                              }
                            }}
                            required
                            minLength={6} // Ensures at least 6 characters
                            className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"              
                          />
                        </div>

                        {/* input regimeniva */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                Regimen IVA
                              </label>
                              <input
                                type="text"
                                name="regimeniva"
                                id="regimeniva"
                                value={customerData.regimeniva}
                                onChange={(e) => setCustomerData({ ...customerData, regimeniva: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        {/* input renta */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                Renta
                              </label>
                              <select
                                type="text"
                                name="renta"
                                id="renta"
                                value={customerData.renta}
                                onChange={(e) => setCustomerData({ ...customerData, renta: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"                  
                              >
                                <option value=""></option>
                                <option value="si">Si</option>              
                                <option value="no">No</option>    
                              </select>  
                            </div>
                          </>
                          ):null}

                        {/* input ica */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                Ica
                              </label>
                              <select
                                type="text"
                                name="ica"
                                id="ica"
                                value={customerData.ica}
                                onChange={(e) => setCustomerData({ ...customerData, ica: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              >
                                <option value=""></option>
                                <option value="si">Si</option>              
                                <option value="no">No</option>    
                              </select>  
                            </div>
                          </>
                          ):null}

                        {/* input porcentaje de reteica */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                % Reteica
                              </label>
                              <input
                                type="text"
                                name="reteica"
                                id="reteica"
                                value={customerData.reteica}
                                onChange={(e) => setCustomerData({ ...customerData, reteica: e.target.value })}
                                required
                                minLength={1} // Ensures at least 1 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        {/* input actividad economica */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                Actividad economica
                              </label>
                              <input
                                type="text"
                                name="actividadeconomica"
                                id="actividadeconomica"
                                value={customerData.actividadeconomica}
                                onChange={(e) => setCustomerData({ ...customerData, actividadeconomica: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        {/* input codigo ciiu */}
                        {customerData.type===2?
                          (
                          <>
                            <div className="py-4 pl-1 px-2 flex">
                              <label htmlFor="regimeniva" className="block text-sm font-medium text-gray-700 self-center">
                                Codigo ciiu
                              </label>
                              <input
                                type="text"
                                name="ciiu"
                                id="ciiu"
                                value={customerData.ciiu}
                                onChange={(e) => setCustomerData({ ...customerData, ciiu: e.target.value })}
                                required
                                minLength={3} // Ensures at least 3 characters
                                className="ml-2 mt-1 block w-full pl-3 pr-6 py-2 text-base bg-slate-100 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                            </div>
                          </>
                          ):null}

                        <br />                   
                        <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
            </Dialog.Panel>
          </Dialog>
      ):null}      
    </div>    

    <div>
      {showPopup ? (
        <div>
          <PopupMessage msg={`A su cuenta fueron cargados $ ${Number(transactionAmount).toLocaleString()}`}/>         
        </div>
      ) : null}

      {showLoading ? 
      (
        <LoadingComponent msg="Analizando..." show={transactionStatus} />
      ):null}    
    </div>
    </>
  );
};

export default PaymentResult;
