import { useState, useEffect, useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import { HomeIcon, CalculatorIcon, BuildingOffice2Icon, BanknotesIcon, HomeModernIcon, Cog8ToothIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import logo from "../images/logo.svg";
import AuthContext from "./AuthContext";
import { NavLink, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

export default function SideBar() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true); // State to control sidebar expansion
  const { User, Membership } = useContext(AuthContext);
  const navigate = useNavigate();

  const user = {
    userId: User.sub,
    name: User.name.charAt(0).toUpperCase() + User.name.slice(1),
    email: User.email,
  };

  // Function to capitalize the first letter of a string  
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigation = [
    { name: 'Inicio', icon: HomeIcon, href: '/dashboard' },
    // { name: 'Informacion Catastral', icon: BuildingOffice2Icon, href: '/infoCatastro' },
    { name: 'Estimacion de valor', icon: CalculatorIcon, href: '/appraisal' },
    { name: 'Transacciones', icon: BanknotesIcon, href: '/transacciones' },
    // { name: 'Mercado de Vivienda Nueva', icon: HomeModernIcon, href: '/proyectos' },
    { name: 'Administrar mi cuenta', icon: Cog8ToothIcon, href: '/admin' },
  ];

  const handleLogOut = () => {
    googleLogout();
    window.localStorage.removeItem('User');
    window.localStorage.removeItem('Membership');
    window.localStorage.removeItem('cognitoTokens');
    window.location.replace("/");
    navigate("/");
  };

  // Contract the sidebar when user interacts with the outlet area
  const handleContentClick = () => {
    setIsSidebarExpanded(false);
  };

  return (
    <div className="min-h-full flex">
      <Disclosure as="nav" className={`bg-gray-100 ${isSidebarExpanded ? 'w-64' : 'w-20'} transition-width duration-300`}>
        {({ open }) => (
          <>
            <div 
              className="flex flex-col h-full" 
              onMouseEnter={() => setIsSidebarExpanded(true)} // Expand on hover
              onMouseLeave={() => setIsSidebarExpanded(false)} // Contract when not hovering
            >
              <div className={`flex items-center justify p-4 border-b border-gray-200 ${isSidebarExpanded ? 'justify-start' : 'justify-center'}`}>
                <img className="h-8 w-auto" src={logo} alt="habi" />
                {isSidebarExpanded && <div className='mx-1.5 font-semibold text-l'>data.habi</div>}
              </div>
              <div className="flex overflow-y-auto">

                <nav className="flex-1 px-2 py-4 space-y-1">

                  <div className='mb-4 flex-1 flex-row '> 
                      <span className="pl-2 bg-gray-100 text-lg text-black font-semibold">{isSidebarExpanded && `Hola ${capitalizeFirstLetter(user.name)}`}</span>
                        <div className="pl-2 pt-4 text-sm font-semibold text-gray-700">{isSidebarExpanded && `Plan actual: ${capitalizeFirstLetter(Membership.membership_type)}`}</div>
                        <div className="pl-2 text-sm font-semibold text-gray-700">{isSidebarExpanded && `Saldo : $ ${Number(Membership.balance).toLocaleString()}`}</div>                      
                  </div>
                  
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? `bg-gray-900 text-white flex items-center px-2 py-2 text-sm font-medium rounded-md ${isSidebarExpanded ? 'justify-start' : 'justify-center'}`
                          : `text-gray-500 hover:bg-gray-700 hover:text-white flex items-center px-2 py-2 text-sm font-medium rounded-md ${isSidebarExpanded ? 'justify-start' : 'justify-center'}`
                      }
                      aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
                    >
                      <item.icon className="h-6 w-6 mr-3" aria-hidden="true" />
                      {isSidebarExpanded && item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>

              <div className="border-t border-gray-200 p-4">
                <button
                  onClick={handleLogOut}
                  className="w-full flex items-center px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white rounded-md"
                >
                  <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-3" aria-hidden="true" />
                  {isSidebarExpanded && 'Cerrar Sesion'}
                </button>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="flex-1 min-h-screen" onClick={handleContentClick}>
        <header className="">
          <div className="mx-auto max-w-9xl px-4 py-2 sm:px-6 lg:px-6">
            {/* Page content goes here */}
          </div>
        </header>
      </div>
    </div>
  );
}
