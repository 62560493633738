import React, { useContext, useEffect, useState, useRef } from "react";
import LocationInputForm from "./LocationInputForm";
import { fetchAppraisal_function } from "./Functions/fetchData";
import LoadingComponent from './LoadingComponent'; 
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import ReactTableComparablesVenta from './Tables/ReactTableComparablesVenta'; 
import ReactTableHabimetroComparablesMetrocuadradoRenta from './Tables/ReactTableHabimetroComparablesMetrocuadradoRenta';
import AuthContext from "./AuthContext";
import { serviceUse } from "./Functions/fetchreact_webapp_habi_data_jul_2024_membership_restapi";
import PopupMessage from "./PopupMessage";
import SatisfactionSurveyPopup from "./SatisfactionSurveyPopup";  // Import the new component

export default function Appraisal() {

  const [dataFromLocationInputForm, setdataFromLocationInputForm] = useState(null); // State to hold the returned data
  const [appraisalFetchData, setAppraisalFetchData] = useState(null); // State to hold the fetched data
  const [loading, setLoading] = useState(false); // State to hold the loading status
  const prevDataRef = useRef(null); // Reference to hold the previous data value

  const [estimatedSalePrice, setEstimatedSalePrice] = useState(null);
  const [estimatedUpperBoundSalePrice, setEstimatedUpperBoundSalePrice] = useState(null);
  const [estimatedLowerBoundSalePrice, setEstimatedLowerBoundSalePrice] = useState(null);
  const [estimatedRentPrice, setEstimatedRentPrice] = useState(null);    

  const [comparablesHabimetroVenta, setcomparablesHabimetroVenta] = useState([]);
  const [comparablesMetrocuadradoVenta, setcomparablesMetrocuadradoVenta] = useState([]);  
  const [comparablesMetrocuadradoArriendo, setcomparablesMetrocuadradoArriendo] = useState([]);  
  const [comparablesTotalesVenta, setcomparablesTotalesVenta] = useState([]);  

  const [estimatedCapRate, setEstimatedCapRate] = useState(null);
  const [estimatedAdmon, setEstimatedAdmon] = useState(null);

  const [transactionStatus, setTransactionStatus] = useState(null);

  // Get the user data from the context
  const { User, Membership, setMembership } = useContext(AuthContext);
  
  const user = {
    userId: User.sub,
    name: User.name,
    email: User.email,
  } 

  //define 'serviceName' and 'serviceCost' for the serviceUse function
  const service = { serviceName: 'appraisal', serviceCost: 10000 };

  // Function to fetch the appraisal data
  const fetchAppraisal = async () => {    
    try {
      const appraisalFetchData = await fetchAppraisal_function({ dataFromLocationInputForm }); 
      // console.log('appraisalFetchData:', appraisalFetchData); 

      // check if appraisalFetchData is empty or null
      if (!appraisalFetchData) {
        alert('No se encontraron datos para la ubicación seleccionada. Por favor intente de nuevo');
        setdataFromLocationInputForm(null); // Update the state with the data
        return;
      } else {
        console.log('Data found for the location:', dataFromLocationInputForm);
        setAppraisalFetchData(appraisalFetchData);
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('No fue posible obtener la información, por favor intente de nuevo');
    } finally {
      setLoading(false); // Hide the loading modal after fetching
    }
  } 

  // Callback function to handle the data returned from LocationInputForm
  const handleGetDataLocationInputForm = (data) => {
    // console.log("Data received from child LocationInputForm:", data); 

    // check if user has enough balance to use the service
    console.log('Membership balance:', Membership.balance);
    if (Membership.balance < service.serviceCost) {      
      alert('No tiene suficiente saldo para realizar la consulta. Por favor recargue su saldo');
      setdataFromLocationInputForm(null); // Update the state with the data 
      return;
    } else {      
      setdataFromLocationInputForm(data); // Update the state with the data 
    }
  };

  // UseEffect to fetch the appraisal data when dataFromLocationInputForm changes
  useEffect(() => {
    if (dataFromLocationInputForm && dataFromLocationInputForm !== prevDataRef.current) {
      prevDataRef.current = dataFromLocationInputForm; // Update the previous data reference
      setLoading(true); // Show the loading modal
      setAppraisalFetchData(null); // Reset the appraisal data
      fetchAppraisal(); // Fetch the appraisal data
    }
  }, [dataFromLocationInputForm]);  
  
  // UseEffect to hide the loading modal when appraisalFetchData is updated and grab the data
  useEffect(() => {
    if (appraisalFetchData) {
      setLoading(false); // Hide the loading modal  
      
      // Check and handle habimetro data
      if (appraisalFetchData.habimetro) {        
        const results_habimetro = appraisalFetchData.habimetro;
        setEstimatedSalePrice((results_habimetro.estimated_value));
        setEstimatedUpperBoundSalePrice((results_habimetro.estimated_value_upper_bound));
        setEstimatedLowerBoundSalePrice((results_habimetro.estimated_value_lower_bound));
        setcomparablesHabimetroVenta(results_habimetro?.comparables || []);
        

      } else {
        // Handle the case when habimetro data is null or empty
        // console.log('Hb data is null or empty');
        alert('No se encontraron datos para la ubicación seleccionada. Por favor intente de nuevo');
        setdataFromLocationInputForm(null); // Update the state with the data
        setAppraisalFetchData(null);        
        setEstimatedSalePrice(null);
        setEstimatedUpperBoundSalePrice(null);
        setEstimatedLowerBoundSalePrice(null);
        setcomparablesHabimetroVenta([]);
        // Reset all metrocuadrado data as well
        setcomparablesMetrocuadradoVenta([]);
        setcomparablesMetrocuadradoArriendo([]);
        setEstimatedCapRate(null);
        setEstimatedAdmon(null);
        return;
      }

      // Handle metrocuadrado data if available
      if (appraisalFetchData.metrocuadrado) {
        const results_metrocuadrado = appraisalFetchData.metrocuadrado;
        setcomparablesMetrocuadradoVenta(results_metrocuadrado.comparables?.df_comparables_venta_dict || []);
        setcomparablesMetrocuadradoArriendo(results_metrocuadrado.comparables?.df_comparables_renta_dict || []);        

        const caprates = results_metrocuadrado.caprates;

        // Combine all cap rates into one array
        const allCapRates = [
          ...Object.values(caprates.cap_rate_by_antiguedad || {}),
          ...Object.values(caprates.cap_rate_by_area || {})
        ];

        // Calculate the average
        const totalCapRates = allCapRates.reduce((sum, rate) => sum + rate, 0);
        const averageCapRate = totalCapRates / allCapRates.length;

        // Update state with the average Cap Rate
        setEstimatedCapRate(averageCapRate.toFixed(2)); // Round to 2 decimal places 

        setEstimatedAdmon(results_metrocuadrado.comparables.avg_administracion_m2 || 0);
      } else {
        // If metrocuadrado data is null, reset corresponding states
        console.log('listings data is null');
        setcomparablesMetrocuadradoVenta([]);
        setcomparablesMetrocuadradoArriendo([]);
        setEstimatedCapRate(null);
        setEstimatedAdmon(null);
      }      

      // Determine which comparables venta to use
      if (comparablesHabimetroVenta.length > 0) {                
        // console.log('Setting comparablesTotalesVenta from Hb');
        setcomparablesTotalesVenta(comparablesHabimetroVenta);
      } else if (comparablesMetrocuadradoVenta.length > 0) {
        // console.log('Setting comparablesTotalesVenta from listings');
        setcomparablesTotalesVenta(comparablesMetrocuadradoVenta);
      } else {
        console.log('No comparables found, setting comparablesTotalesVenta to empty array');
        // console.log('comparablesHabimetroVenta:', comparablesHabimetroVenta ,'comparablesMetrocuadradoVenta:', comparablesMetrocuadradoVenta);
        setcomparablesTotalesVenta([]); // Set to an empty array if neither is available
      }      
    } else {
      // Reset all state variables if no appraisalFetchData
      setEstimatedSalePrice(null);
      setEstimatedUpperBoundSalePrice(null);
      setEstimatedLowerBoundSalePrice(null);
      setEstimatedRentPrice(null);
      setEstimatedAdmon(null);
      setEstimatedCapRate(null);  
      setcomparablesTotalesVenta([]);
      setcomparablesMetrocuadradoArriendo([]);
      setAppraisalFetchData(null);
    }
  }, [appraisalFetchData ]);  

  // UseEffect to set comparablesTotalesVenta based on comparablesHabimetroVenta or comparablesMetrocuadradoVenta
  useEffect(() => {
    if (comparablesHabimetroVenta.length > 0) {
        // console.log('Setting comparablesTotalesVenta from Hb');
        setcomparablesTotalesVenta(comparablesHabimetroVenta);
    } else if (comparablesMetrocuadradoVenta.length > 0) {
        // console.log('Setting comparablesTotalesVenta from listings');
        setcomparablesTotalesVenta(comparablesMetrocuadradoVenta);
    } else {
        console.log('No comparables found, setting comparablesTotalesVenta to empty array');
        setcomparablesTotalesVenta([]);
    }
}, [comparablesHabimetroVenta, comparablesMetrocuadradoVenta]);




  // UseEffect to charge the user for the service
  useEffect(() => {
    if (estimatedSalePrice) {
      console.log('Charging user for service:', service);

      const chargeUserForService = async () => {
        try {
          const response = await serviceUse(user.userId, user.name, user.email, service.serviceName, service.serviceCost);
          console.log(response);      
          setMembership(response);          
          
          // set the transaction status to APPROVED for 4 seconds
          setTransactionStatus('APPROVED');
          setTimeout(() => {
            setTransactionStatus(null);
          }, 4000);          
          
        } catch (error) {
          console.error('Error charging user for service:', error);
        }
      };
  
      chargeUserForService();
    }    
  }, [estimatedSalePrice]);

  // UseEffect to reset all states if dataFromLocationInputForm is null
  useEffect(() => {
    if (dataFromLocationInputForm === null) {
      // Reset all states
      setAppraisalFetchData(null);
      setEstimatedSalePrice(null);
      setEstimatedUpperBoundSalePrice(null);
      setEstimatedLowerBoundSalePrice(null);
      setEstimatedRentPrice(null);
      setcomparablesHabimetroVenta([]);
      setcomparablesMetrocuadradoVenta([]);
      setcomparablesMetrocuadradoArriendo([]);
      setcomparablesTotalesVenta([]);
      setEstimatedCapRate(null);
      setEstimatedAdmon(null);
      console.log("All states reset due to null dataFromLocationInputForm");
    }
  }, [dataFromLocationInputForm]);
  

  return (
    <div className="bg-white flex-1 mt-4">
      <div className="flex-column justify-start border-b-4 border-slate-100">          
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Estimación Valor</h1>
        <p className="mb-2 text-slate-600 text-sm">Obtén una estimación precisa del valor de una propiedad, para venta o renta. Solo ingresa la dirección y ubica el lugar en el mapa interactivo, indica las características del inmueble y obtén una estimación con valores actuales y acordes a la oferta del mercado.</p>
        <p className="mt-2 mb-2 text-slate-600 text-sm">El costo de este servicio es de <strong>${Number(service.serviceCost).toLocaleString()}</strong>, una vez generado será descontado de tu saldo. Al usar este servicio aceptas nuestros términos y condiciones.</p>
      </div>
      
      <div>        
        <LocationInputForm 
          onSubmit={handleGetDataLocationInputForm} 
          turnCreatePolygon={false} 
          markersVenta={comparablesTotalesVenta} 
          markersRenta={comparablesMetrocuadradoArriendo} 
          citiestoShow={['Barranquilla','Bello','Bogota','Cali','Chia','Envigado','Funza','Itagui','La Estrella','Medellin','Mosquera','Sabaneta','Soacha']}
        />
      </div>

      {/* Loading component */}
      <LoadingComponent msg="Analizando..." show={loading}/> 
         
      {/* Render estimated sale values if available */}
      {estimatedSalePrice > 0 && dataFromLocationInputForm && (
        <>
          <div className="mb-4 flex border-b-2 border-slate-100 ">   
            <div className="flex-1">       
              <h2 className="text-2xl font-bold text-purple-800">Estimación de venta: $ {Number(Math.round(estimatedSalePrice/ 1000) * 1000).toLocaleString()} COP </h2>         
              <p className="text-xl text-purple-600">$ {Number(Math.round(estimatedSalePrice / dataFromLocationInputForm.area/ 1000) * 1000).toLocaleString()} COP/m2</p>     
            </div>
            <div>
              <div className="flex">
                <ArrowUpIcon className="h-6 w-6 texg-slate-500 mx-4" aria-hidden="true" />    
                <div className="flex">          
                  <h2 className="text-l font-bold text-gray-600">${Number(Math.round(estimatedUpperBoundSalePrice / 1000) * 1000).toLocaleString()} COP</h2>
                  <p className="ml-4 text-gray-500">$ {Number(Math.round(estimatedUpperBoundSalePrice / (dataFromLocationInputForm.area))).toLocaleString()} COP/m2</p>
                </div>
              </div>

              <div className="mt-1 flex">
                <ArrowDownIcon className="h-6 w-6 texg-slate-500 mx-4" aria-hidden="true" />    
                <div className="flex">          
                  <h2 className="text-l font-bold text-gray-600">${Number(Math.round(estimatedLowerBoundSalePrice/ 1000) * 1000).toLocaleString()} COP</h2>         
                  <p className="ml-4 text-gray-500">$ {Number(Math.round(estimatedLowerBoundSalePrice / (dataFromLocationInputForm.area))).toLocaleString()} COP/m2</p>
                </div>
              </div>
            </div>
          </div>
          {/* conditionally render caprate & rent estimate values info if estimatedCapRate exists */}
          {estimatedCapRate > 0 &&
          (          
          <div className="flex border-b-2 border-slate-100">
            <div className="my-4">                           
              <h2 className="text-2xl font-bold text-purple-800">Estimación de renta: $ {Number(Math.round((estimatedSalePrice * (estimatedCapRate / 100) / 12) / 1000) * 1000).toLocaleString()} COP</h2>              
              <h4 className="text-l text-purple-600">$ {Number(Math.round((estimatedSalePrice * (estimatedCapRate / 100) / 12) / dataFromLocationInputForm.area / 1000) * 1000).toLocaleString()} COP/m2</h4>

              <h3 className="mt-2 text-xl font-semibold text-purple-600">CapRate anual: {estimatedCapRate}%</h3> 
              <h3 className="mt-1 text-xl font-semibold text-purple-500">Payback (años): {(1/((estimatedCapRate / 100))).toFixed(1)}</h3> 
            </div>
            <div className="flex-1 self-center ">
              <div className="flex place-content-end">
                <ArrowUpIcon className="h-6 w-6 texg-slate-500 mx-4" aria-hidden="true" />    
                <div className="flex">          
                  <h2 className="text-l font-bold text-gray-600">$ { Number(Math.round(estimatedUpperBoundSalePrice * ((estimatedCapRate / 100) / 12) / 1000) * 1000).toLocaleString() } COP</h2>                           
                  <p className="ml-4 text-gray-500">$ {Number(Math.round((estimatedUpperBoundSalePrice * (estimatedCapRate / 100) / 12) / dataFromLocationInputForm.area / 1000) * 1000).toLocaleString()} COP/m2</p>
                </div>
              </div>

              <div className="mt-1 flex place-content-end">
                <ArrowDownIcon className="h-6 w-6 texg-slate-100 mx-4" aria-hidden="true" />    
                <div className="flex">          
                <h2 className="text-l font-bold text-gray-600">$ { Number(Math.round(estimatedLowerBoundSalePrice * ((estimatedCapRate / 100) / 12)/ 1000) * 1000).toLocaleString()} COP</h2>                         
                <p className="ml-4 text-gray-500">$ {Number(Math.round((estimatedLowerBoundSalePrice * (estimatedCapRate / 100) / 12) / dataFromLocationInputForm.area / 1000) * 1000).toLocaleString()} COP/m2</p>
                </div>
              </div>
            </div>
          </div>
          
          )} 

          {/* Administration estimation, not for 'casa' property type and if estimatedAdmon exists*/}
          {dataFromLocationInputForm.propertyType !== 'casa' && estimatedAdmon > 0 && (
            <div className="my-4">                
              <h2 className="text-xl font-bold text-slate-800">Estimacion de Administracion $ { Number(Math.round(estimatedAdmon * (dataFromLocationInputForm.area)/ 1000) * 1000).toLocaleString() } COP</h2>
              <h4 className="text-l text-purple-600">$ { Number(Math.round(estimatedAdmon)).toLocaleString() } COP/m2</h4>
            </div>
          )}
        </>
      )}

      {/* Comparables sale table */}
      {estimatedSalePrice > 0 && dataFromLocationInputForm && (
        <div className="mb-4">
          <ReactTableComparablesVenta comparables={comparablesTotalesVenta} />
        </div>
      )}

      {/* Comparables rent table if metrocuadrado data is available */}
      {appraisalFetchData && dataFromLocationInputForm && comparablesMetrocuadradoArriendo.length > 0 && (
        <div className="mb-4">
          <ReactTableHabimetroComparablesMetrocuadradoRenta comparables={comparablesMetrocuadradoArriendo}  />
        </div>
      )} 

      {/* Popup for service charge */}
      {transactionStatus === 'APPROVED' && (
        <div>
          <PopupMessage msg={`de su cuenta fueron descargados $ ${Number(service.serviceCost).toLocaleString()}`}/>         
        </div>
      )}      
    </div>
  );
}
