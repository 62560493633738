
import Appraisal from '../components/Appraisal';

export default function AppraisalPage() {

  
  return (
    <div className="bg-white mx-auto max-w-9xl py-4 sm:px-6 lg:px-8 flex-1">      
        <Appraisal/>  
    </div>

  );
}