import React, { useContext, useEffect, useState,useRef } from "react";
import LocationInputForm from './LocationInputForm';
import {fetchSnr} from "./Functions/fetchData";
import LoadingComponent from './LoadingComponent'; 
import AuthContext from "./AuthContext";
import PlotPrecios from "./graphs/PlotPrices";
import PlotPricesbyAge from "./graphs/PlotPricesbyAge";
import PlotPricesbySizePercentage from "./graphs/PlotPricesbySizePercentage";
import PlotPricesbySize from "./graphs/PlotPricesByArea";
import PlotPieChartAreas from "./graphs/PlotPieChartAreas";
import PlotPricesCombined from "./graphs/PlotPricesCombined";  
import ReactTableTransacciones from './Tables/ReactTableTransacciones'; 
import { getBalance,serviceUse } from "./Functions/fetchreact_webapp_habi_data_jul_2024_membership_restapi";
import PopupMessage from "./PopupMessage";


export default function Transacciones() {

  const [dataFromLocationInputForm, setdataFromLocationInputForm] = useState(null); // State to hold the returned data

  const [comparablesTotalesVenta, setComparablesTotalesVenta] = useState([]);

  const [TransaccionesFetchData, setTransaccionesFetchData] = useState(null); // State to hold the fetched data

  const [loading, setLoading] = useState(false); // State to hold the loading status
  const prevDataRef = useRef(null); // Reference to hold the previous data value
  const [transactionStatus, setTransactionStatus] = useState(null);

  const [avg_price_m2_last_5y_monthly, setAvgPriceM2Last5YMonthly] = useState(null);
  const [avg_price_m2_last_5y_monthly_by_area, setAvgPriceM2Last5YMonthlyByArea] = useState(null);
  const [share_units_total_sold_last_5y_total, setShareUnitsTotalSoldLast5YTotal] = useState(null);
  const [sold_area_last_5y_yearly, setSoldAreaLast5YYearly] = useState(null);
  const [df_5years, setDf5Years] = useState(null);
  const [precio_venta_m2_por_antiguedad_5y_total, setPrecioVentaM2PorAntiguedad5YTotal] = useState(null);




  // Get the user data from the context
  const { User, Membership, setMembership,serviceCosts } = useContext(AuthContext);
  
  const user = {
    userId: User.sub,
    name: User.name,
    email: User.email,
  } 

  //define 'serviceName' and 'serviceCost' for the serviceUse function
  const service={serviceName:'transaccciones_report', serviceCost:20000};


  // Function to fetch the transacciones data
  const fetchTransacciones = async () => {    
    try {            
      const transaccionesFetchData = await fetchSnr({ dataFromLocationInputForm }); 
      console.log('transaccionesFetchData:', transaccionesFetchData); 
      
      // check if transaccionesFetchData is empty or null
      if (!transaccionesFetchData) {
        alert('No se encontraron datos para la ubicación seleccionada. Por favor intente de nuevo');
        setdataFromLocationInputForm(null); // Update the state with the data
        return;
      }
      else{
        console.log('Data found for the location:', dataFromLocationInputForm);
        setTransaccionesFetchData(transaccionesFetchData);        
      }            
    }
    catch (error) {
      console.error('Error fetching data:', error);
      alert('No fue posible obtener la información, por favor intente de nuevo');

    } finally {
      setLoading(false); // Hide the loading modal after fetching
    }
  } 

  
  // Callback function to handle the data returned from LocationInputForm
  const handleGetDataLocationInputForm = (data) => {
    // console.log("Data received from child LocationInputForm:", data); 

    // check if user has enough balance to use the service
    const checkBalance = async () => {
      try {
        const balance = await getBalance(user.userId);
        console.log('Balance:', balance);
        setMembership(balance);
      } catch (error) {
        console.error('Error getting balance:', error);
      }
    };

    checkBalance();
    console.log('Membership balance:', Membership.balance);
    if (Membership.balance < service.serviceCost) {      
            
      alert('No tiene suficiente saldo para realizar la consulta. Por favor recargue su saldo');

      //setdataFormlocationInputForm to null to reset the form
      setdataFromLocationInputForm(null); // Update the state with the data 
      return;
    }
    else{      
       setdataFromLocationInputForm(data); // Update the state with the data       
    }
  };


  // UseEffect to fetch the transacciones data when dataFromLocationInputForm changes
  useEffect(() => {
    // if dataFromLocationInputForm is not null, and different from the previous value    
    if (dataFromLocationInputForm && dataFromLocationInputForm !== prevDataRef.current) {
      prevDataRef.current = dataFromLocationInputForm; // Update the previous data reference
      setLoading(true); // Show the loading modal
      setTransaccionesFetchData(null); // Reset the appraisal data
      fetchTransacciones(); // Fetch the appraisal data
    }
    }, 
    [dataFromLocationInputForm]);  

  
  // UseEffect to hide the loading modal when appraisalFetchData is updated and grab the data
  useEffect(() => {
    if(TransaccionesFetchData) {
      setLoading(false); // Hide the loading modal 
      
      
      setAvgPriceM2Last5YMonthly(TransaccionesFetchData.avg_price_m2_last_5y_monthly);
      setAvgPriceM2Last5YMonthlyByArea(TransaccionesFetchData.avg_price_m2_last_5y_monthly_by_area);
      setShareUnitsTotalSoldLast5YTotal(TransaccionesFetchData.share_units_total_sold_last_5y_total);
      setSoldAreaLast5YYearly(TransaccionesFetchData.sold_area_last_5y_yearly);
      setDf5Years(TransaccionesFetchData.df_5years);
      setPrecioVentaM2PorAntiguedad5YTotal(TransaccionesFetchData.precio_venta_m2_por_antiguedad_5y_total);
      setComparablesTotalesVenta(TransaccionesFetchData.df_12months_sample_dict);      
      
        
    }

    else {
      setAvgPriceM2Last5YMonthly(null);
      setAvgPriceM2Last5YMonthlyByArea(null);
      setShareUnitsTotalSoldLast5YTotal(null);
      setSoldAreaLast5YYearly(null);
      setDf5Years(null);
      setPrecioVentaM2PorAntiguedad5YTotal(null);  
      setComparablesTotalesVenta(null);      
      setTransaccionesFetchData(null);
    }
    
  }, [TransaccionesFetchData]); 


// UseEffect to charge the user for the service
useEffect(() => {
  if (comparablesTotalesVenta && comparablesTotalesVenta.length > 0) {
    console.log('Charging user for service:', service);

    const chargeUserForService = async () => {
      try {
        const response = await serviceUse(user.userId, user.name, user.email, service.serviceName, service.serviceCost);
        console.log(response);      
        setMembership(response);          
        
        // set the transaction status to APPROVED for 4 seconds
        setTransactionStatus('APPROVED');
        setTimeout(() => {
          setTransactionStatus(null);
        }, 4000);          
        
      } catch (error) {
        console.error('Error charging user for service:', error);
      }
    };

    chargeUserForService();
  }    
}, [comparablesTotalesVenta]);

     
  
  return (
    <div className="bg-white flex-1 mt-4" >

      <div className="flex-column justify-start border-b-4 border-slate-100">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Transacciones</h1>
        <p className="mb-2 text-slate-600 text-sm">Una vez definida una ubicación, podrá definir un polígono para el análisis, de lo contrario el análisis se realizará en un radio de 500m. indica las características del inmueble y te proporcionaremos informacion de las transacciones más recientes ejecutadas en la zona.</p>
        <p className="mt-4 mb-2 text-slate-600 text-sm">El costo de este servicio es de <strong>${Number(service.serviceCost).toLocaleString()}</strong>, una vez generado será descontado de tu saldo. Al usar este servicio aceptas nuestros términos y condiciones.</p>
               
      </div>
    
      <div className="">        
        <LocationInputForm onSubmit={handleGetDataLocationInputForm} turnCreatePolygon={true} markersVenta={comparablesTotalesVenta} citiestoShow={['Bogota']}/>
      </div>

      
      {/* when dataFromLocationInputForm fetch will take place */}
      <LoadingComponent msg="Analizando..." show={loading}/> {/* Show the loading modal when loading */}     

        
      
      {/* if TransaccionesFetchData exists  */}
      {TransaccionesFetchData && dataFromLocationInputForm &&
      (      
      <>  

        {/*  */}
        <div>

        </div>

        
        {/* plot precio_venta_m2_por_antiguedad_5y_total if exist and also if lenght >0*/}        
        {precio_venta_m2_por_antiguedad_5y_total && Object.keys(precio_venta_m2_por_antiguedad_5y_total).length > 0 ? 
        (
        <div className="mt-10 border-t-4 border-slate-100">
          <PlotPricesbyAge precio_venta_m2_por_antiguedad={precio_venta_m2_por_antiguedad_5y_total} age={dataFromLocationInputForm.age}/>
        </div>
        ):null }

        {/* plot avg_price_m2_last_5y_monthly_by_area if exist and also if lenght >0 and precio_venta_m2_por_antiguedad_5y_total length is below 0 */}
        {avg_price_m2_last_5y_monthly_by_area && Object.keys(avg_price_m2_last_5y_monthly_by_area).length > 0  && Object.keys(precio_venta_m2_por_antiguedad_5y_total).length < 0?
        (
        <div className="mt-10 border-t-4 border-slate-100">
          <PlotPricesbySize prices_to_plot={avg_price_m2_last_5y_monthly_by_area} propertyType={dataFromLocationInputForm.propertyType} area={dataFromLocationInputForm.area}/>
        </div>         
        ):null }

        {/* plot if there if avg_price_m2_last_5y_monthly_by_area and precio_venta_m2_por_antiguedad_5y_total are both lenght >0  */}
        {avg_price_m2_last_5y_monthly_by_area && Object.keys(avg_price_m2_last_5y_monthly_by_area).length > 0 && precio_venta_m2_por_antiguedad_5y_total && Object.keys(precio_venta_m2_por_antiguedad_5y_total).length > 0 ?
        (
        <div className="mt-10 border-t-4 border-slate-100">
          <PlotPricesCombined prices_to_plot_area={avg_price_m2_last_5y_monthly_by_area} prices_to_plot_age={precio_venta_m2_por_antiguedad_5y_total} propertyType={dataFromLocationInputForm.propertyType} area={dataFromLocationInputForm.area} age={dataFromLocationInputForm.age}/>
        </div>         
        ):null }



        {/* plot share of areas sells in the past 5y */}
        {share_units_total_sold_last_5y_total ?
        (
        <div className="mt-10 border-t-4 border-slate-100">
          <PlotPieChartAreas share_units_total_sold_last_5y_total={share_units_total_sold_last_5y_total.share_units_total_sold_last_5y_total} total_units_sold_past_5y={share_units_total_sold_last_5y_total.total_units_sold_past_5y} propertyType={dataFromLocationInputForm.propertyType}/>
        </div>
        ): null}

        {/*reactable for comparables */}
        <div className="mt-2 border-t-4 border-slate-100">         
        {comparablesTotalesVenta && comparablesTotalesVenta.length > 0 && (
          <ReactTableTransacciones comparables={comparablesTotalesVenta} />
        )}              
        </div> 

      </>
      )
      }

      {/* shows popup of the service charge made */}
      {transactionStatus === 'APPROVED' ? 
      (
      <div>
        <PopupMessage msg={`de su cuenta fueron descargados $ ${Number(service.serviceCost).toLocaleString()}`}/>         
      </div>
      ) : null
      }       

      

    </div>
  )
}