import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const areas_dict_total = {
    'apartamento': {
        "Micro": [0, 30],
        "Pequeño": [30, 60],
        "Mediano": [60, 120],
        "Grande": [120, 250],
        "Extra grande": [250, 1000]
    },
    'casa': {
        "Micro": [0, 50],
        "Pequeño": [50, 120],
        "Mediano": [120, 250],
        "Grande": [250, 500],
        "Extra grande": [500, 1000],
        "Super grande": [1000, 2000],
        "Mega grande": [2000, 10000]
    },
};

const PlotPieChartAreas = ({ share_units_total_sold_last_5y_total, total_units_sold_past_5y, propertyType }) => {
  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    if (share_units_total_sold_last_5y_total && Object.keys(share_units_total_sold_last_5y_total).length > 0) {
      
      const areas_dict = areas_dict_total[propertyType];
      
      const labels = Object.keys(share_units_total_sold_last_5y_total);  // Labels for the pie chart (without area brackets)
      const legendLabels = labels.map(label => {
        const [min, max] = areas_dict[label];
        return `${label} [${min}-${max} m2]`;  // Labels for the legend (with area brackets)
      });
      const values = Object.values(share_units_total_sold_last_5y_total);

      const data = [
        {
          values: values,
          labels: legendLabels,  // Use legend labels here for the legend
          type: 'pie',
          text: labels,  // Use labels without brackets for the pie chart
          hovertemplate: '%{label}: %{value}%<extra></extra>',  // Format for the hover tooltip
          textinfo: 'text+percent',  // Display the text (without area brackets) and percentages on the chart
          textposition: 'inside',
          automargin: true,
        },
      ];

      const layout = {
        title: {
        text: `Distribución porcentual de las <span style="font-weight:bold;">${total_units_sold_past_5y}</span> unidades vendidas en los últimos 5 años`,
        font: {
            family: 'Arial, sans-serif',
            size: 16,
            color: '#000',
        },
        x: 0.5, // Center the title
        xanchor: 'center',
    },
        showlegend: true,
        margin: { t: 50, b: 50, l: 50, r: 50 },
        height: 400,
      };

      setPlotData({ data, layout });
    } else {
      // console.warn("share_units_total_sold_last_5y_total is undefined or empty");
      setPlotData(null);
    }
  }, [share_units_total_sold_last_5y_total, total_units_sold_past_5y, propertyType]);

  return (
    <div style={{ maxWidth: '100%', width: '100%', padding: '0 20px', boxSizing: 'border-box' }} className='mt-2'>
      {plotData ? (
        <Plot 
          data={plotData.data} 
          layout={plotData.layout} 
          useResizeHandler={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default PlotPieChartAreas;
