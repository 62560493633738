import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { FolderArrowDownIcon } from '@heroicons/react/24/outline';

const ReactTableTransacciones = ({ comparables }) => {
  const safeComparables = comparables || [];

  // Column rename map
  const columnRenameMap = {
    'fecha_documento': 'Fecha transacción',
  };

  const columnsToShow = useMemo(() => [
    'Tipo',
    'Direccion',   
    '$ COP',   
    'Area (m2)',
    '$ COP/m2',
    'fecha_documento',
    'Año construccion',
  ], []);
  
  // Prepare the data
  const data = useMemo(() => {
    return safeComparables.map(comparable => {
      const row = {};
      columnsToShow.forEach(col => {
        const header = columnRenameMap[col] || col; // Use the renamed column name
        if (col === '$ COP') {
          const pricePerM2 = comparable['$ MM COP/m2'];
          const area = comparable['Area (m2)'];
          row[header] = pricePerM2 && area ? Number(pricePerM2 * area).toLocaleString() : null;
        }
        else if (col === '$ COP/m2') {          
          row[header] = comparable['$ MM COP/m2'] ? Number(Math.round(comparable['$ MM COP/m2'])).toLocaleString() : null;
        }
        else {
          row[header] = comparable[col];
        }
      });
      return row;
    });
  }, [safeComparables, columnsToShow]);

  // Define the columns dynamically
  const columns = useMemo(() => {
    return columnsToShow.map(col => {
      const header = columnRenameMap[col] || col; // Use the renamed column name
      if (col === '$ COP/m2') {
        return {
          Header: header,
          accessor: header,
          Cell: ({ value }) => (
            <div className='bg-blue-50'>
              {value}
            </div>
          ),
        };
      }
      return {
        Header: header,
        accessor: header,
      };
    });
  }, [columnsToShow]);

  // Use the useTable hook to create the table instance
  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
    XLSX.writeFile(workbook, 'data_transacciones.xlsx');
  };

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className='p-2 font-bold text-white bg-blue-800'>
          Algunas transacciones recientes, de acuerdo a las características suministradas
        </div>

        <table className='flex-1 w-full border-collapse' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className='p-2 text-center bg-blue-900 text-white font-semibold sticky top-0'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td className='text-center' {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Download button */}
      <div className='flex justify-start'>
        <button
          onClick={exportToExcel}
          className='flex my-4 p-2 border-2 border-slate-500 rounded-lg hover:bg-indigo-800 hover:text-white'
        >
          <FolderArrowDownIcon className='h-6 w-6 mr-2' aria-hidden='true' />
          Descargar
        </button>
      </div>
    </div>
  );
};

export default ReactTableTransacciones;
