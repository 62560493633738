import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export default function PopupMessage({ msg }) {
  const [open, setOpen] = useState(true);

  return (
    <Dialog 
      open={open} 
      onClose={() => setOpen(false)} 
      className="fixed inset-0 z-[9999] flex items-end justify-center p-4 sm:items-center sm:p-0"
    >
      {/* Optional overlay to darken the background */}
      <Dialog.Overlay 
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        style={{ zIndex: 9998 }} // Ensure the overlay is beneath the popup
      />

      <Dialog.Panel 
        className="relative bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        style={{ zIndex: 9999 }} // Ensure the panel is above everything
      >
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                ¡La transacción fue exitosa!
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-md text-gray-700">
                  {msg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
