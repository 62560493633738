import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const ageCategories = {
  '0-5 años': [0, 5],
  '6-10 años': [6, 10],
  '11-15 años': [11, 15],
  '16-20 años': [16, 20],
  'mas de 20 años': [21, Infinity],
};

// get the current year
const currentYear = new Date().getFullYear();


// Function to calculate a moving average (keeping the first actual data point)
const movingAverage = (data, windowSize) => {
  let result = Array(data.length).fill(null);

  if (data.length > 0) {
    // Keep the first non-null value
    const firstValueIndex = data.findIndex(val => val !== null);
    if (firstValueIndex !== -1) {
      result[firstValueIndex] = data[firstValueIndex];
    }

    // Apply the moving average after the first non-null value
    for (let i = firstValueIndex + 1; i < data.length; i++) {
      let sum = 0;
      let count = 0;

      // Calculate the average based on the window size
      for (let j = 0; j < windowSize && i - j >= firstValueIndex; j++) {
        sum += data[i - j];
        count++;
      }

      result[i] = sum / count;
    }
  }

  return result;
};

const PlotPricesbyAge = ({ precio_venta_m2_por_antiguedad, age }) => {
  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    if (precio_venta_m2_por_antiguedad && Object.keys(precio_venta_m2_por_antiguedad).length > 0) {
      const months = Object.keys(precio_venta_m2_por_antiguedad);

      const categories = Object.keys(ageCategories);

      // Filter categories based on the passed age or select all if age is not provided
      let selectedCategories = categories;
      if (age !== undefined) {
        selectedCategories = categories.filter(category => {
          const [min, max] = ageCategories[category];
          return age >= min && age <= max;
        });
      }

      // Calculate trendlines for the selected categories
      const data = selectedCategories.map((category, index) => {
        const yValues = months.map(month => precio_venta_m2_por_antiguedad[month]?.[category] || null);

        // Fill in missing values to maintain continuous lines
        for (let i = 1; i < yValues.length; i++) {
          if (!yValues[i]) {
            yValues[i] = yValues[i - 1];
          }
        }

        // Calculate the moving average (6-month window) to represent the trendline
        const smoothedValues = movingAverage(yValues, 8);

        return {
          x: months,
          y: smoothedValues,
          type: 'scatter',
          mode: 'lines',
          name: `${category}`, // Show the trendline category in the legend
          line: {
            color: age === undefined ? `hsl(${index * 60}, 70%, 50%)` : 'blue-400', // Different colors for categories
          },
          hovertemplate: 'fecha: %{x}<br>$ %{y:,.1f} MM COP/m2',
        };
      });

      const layout = {
        title: age !== undefined 
          ? `$MM COP/m2 comportamiento promedio venta en los últimos 5 años para inmuebles construidos en ${currentYear - age}` 
          : `$MM COP/m2 comportamiento promedio venta en los últimos 5 años por edades`,
        xaxis: {
          title: '',
          tickangle: 0,
          tickfont: { size: 10 },
        },
        yaxis: {
          title: '',
          tickfont: { size: 12 },
        },
        legend: {
          title: {
            text: 'Edades',
          },
        },
        modebar: {
          orientation: 'v',
          x: 1,
          xanchor: 'right',
          y: 1,
          yanchor: 'top',
        },
        margin: { t: 50, b: 50, l: 50, r: 50 },
      };

      setPlotData({ data, layout });
    } else {
      console.warn("precio_venta_m2_por_antiguedad is undefined or empty");
      setPlotData(null);
    }
  }, [precio_venta_m2_por_antiguedad, age]);

  return (
    <div style={{ maxWidth: '100%', width: '100%', padding: '0 20px', boxSizing: 'border-box' }} className='mt-2'>
      {plotData ? (
        <Plot 
          data={plotData.data} 
          layout={plotData.layout} 
          useResizeHandler={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <p>No hay suficiente informacion para construir una grafica de comportamiento de precios con relacion a la edad indicada</p>
      )}
    </div>
  );
};

export default PlotPricesbyAge;
