import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { FolderArrowDownIcon } from '@heroicons/react/24/outline';

const ReactTableCatastroBog = ({ properties }) => {

  // Mapping of old column names to new column names from the properties data fecth from Bogota API
  const columnRenameMap = {
    'OBJECTID': 'objectid',
    'PRECBARRIO': 'codigo Barrio',
    'PRENBARRIO': 'nombre barrio',
    'PRECMANZ': 'codigo manzana',
    'PRECPREDIO': 'codigo predio',
    'PRECCONS': 'codigo construccion',
    'PRECRESTO': 'codigo resto',
    'PRECHIP': 'codigo chip',
    'PRECEDCATA': 'cedula catastral',
    'PRENUPRE': 'numero predial nacional',
    'PRETPROP': 'tipo predio',
    'PREFINCORP': 'fecha incorporacion siic',
    'PRECLASE': 'clase predio',
    'PREDIRECC': 'direccion oficial',
    'PREMDIRECC': 'marca direccion',
    'PRETDIRECC': 'tipo direccion',
    'PREDSI': 'direccion si',
    'PREATERRE': 'area terreno (m2)',
    'PREACONST': 'area construccion (m2)',
    'PRECZHF': 'zona homogenea fisica',
    'PRECDESTIN': 'destino economico',
    'PREVETUSTZ': 'año construccion',
    'PREVFORMA': 'vigencia formacion',
    'PREVACTUAL': 'vigencia actualizacion',
    'PRECUSO': 'codigo uso',
    'PREAUSO': 'area uso',
    'PREUSOPH': 'uso ph',
    'PREUSONPH': 'uso nph',
    'PREUVIVIEN': 'uso vivienda',
    'PREUCALIF': 'unidad calificada',
    'PREFCALIF': 'fecha calificacion',
    'PREEARMAZ': 'armazon estructura',
    'PREEMUROS': 'muros estructura',
    'PREECUBIER': 'cubierta estructura',
    'PREECONS': 'conservacion estructura',
    'PREAFACHAD': 'fachada acabados',
    'PREACUBIER': 'cubierta muros acabados',
    'PREAPISOS': 'pisos acabados',
    'PREACONS': 'conservacion acabados',
    'PREBTAMANO': 'tamaño baño',
    'PREBENCHAP': 'enchape baño',
    'PREBMOBILI': 'mobiliario baño',
    'PREBCONS': 'conservacion baño',
    'PRECTAMANO': 'tamaño cocina',
    'PRECENCHAP': 'enchape cocina',
    'PRECMOBILI': 'mobiliario cocina',
    'PRECCONSE': 'conservacion cocina',
    'PRECINDUS': 'complemento industria',
    'PREACERCHA': 'altura cerchas',
    'PRECLCONS': 'clase construccion',
    'BARMANPRE': 'barmanpre',
    'PREPUNTAJE': 'puntaje calificacion'
  };

  // define the columns to show in the table
  const columnsToShow = [
    // 'objectid',
    // 'codigo Barrio',
    // 'nombre barrio',
    // 'codigo manzana',
    // 'codigo predio',
    // 'codigo construccion',
    // 'codigo resto',
    'codigo chip',
    'cedula catastral',
    // 'numero predial nacional',
    // 'tipo predio',
    // 'fecha incorporacion siic',
    // 'clase predio',
    'direccion oficial',
    // 'marca direccion',
    // 'tipo direccion',
    // 'direccion si',
    'area terreno (m2)',
    'area construccion (m2)',
    // 'zona homogenea fisica',
    // 'destino economico',
    'año construccion',
    // 'vigencia formacion',
    // 'vigencia actualizacion',
    // 'codigo uso',
    // 'area uso',
    // 'uso ph',
    // 'uso nph',
    'uso vivienda',
    // 'unidad calificada',
    // 'fecha calificacion',
    // 'armazon estructura',
    // 'muros estructura',
    // 'cubierta estructura',
    // 'conservacion estructura',
    // 'fachada acabados',
    // 'cubierta muros acabados',
    // 'pisos acabados',
    // 'conservacion acabados',
    // 'tamaño baño',
    // 'enchape baño',
    // 'mobiliario baño',
    // 'conservacion baño',
    // 'tamaño cocina',
    // 'enchape cocina',
    // 'mobiliario cocina',
    // 'conservacion cocina',
    // 'complemento industria',
    // 'altura cerchas',
    // 'clase construccion',
    // 'barmanpre',
    // 'puntaje calificacion'

  ];

  // Flatten the properties data into an array of attribute objects
  const data = useMemo(() => {
    if (!properties.length) return [];
    return properties.map(property => {
      // Rename the keys in each attribute object
      const renamedAttributes = {};
      for (const key in property.attributes) {
        const newKey = columnRenameMap[key] || key;
        if (columnsToShow.includes(newKey)) {
          renamedAttributes[newKey] = property.attributes[key];
        }
      }
      return renamedAttributes;
    });
  }, [properties]);


  // Generate columns dynamically based on the keys in the attributes
  const columns = useMemo(() => {
    if (data.length === 0) return [];

    // Get unique keys from the attributes of the first item
    const keys = Object.keys(data[0]);

    return keys.map(key => ({
      Header: key,
      accessor: key, // accessor is the "key" in the data
      Cell: ({ value }) => {
        if (key === 'area terreno (m2)' || key === 'area construccion (m2)') {
          return parseFloat(value).toFixed(1);
        }
        if (typeof value === 'object') {
          return JSON.stringify(value);
        }
        return value;
      },
    }));
  }, [data]);

  // Use the useTable hook to create the table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (data.length === 0) {
    return <p></p>;
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <table className='flex-1 w-full border-collapse'
        {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className='text-center bg-blue-900 text-white semi-bold sticky top-0'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td className='text-center'
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* download button */}
      <div className='flex justify-start'>      
      <button 
          onClick={exportToExcel} className='flex my-4 p-2 border-2 border-slate-500 rounded-lg hover:bg-indigo-800 hover:text-white'>
          <FolderArrowDownIcon className='h-6 w-6 mr-2' aria-hidden='true' />
          Descargar
        </button>
      </div>
    </div>
  );
};

export default ReactTableCatastroBog;
