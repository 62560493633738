// fetchGeolocationBgta.js


// Function to fetch the lotcodigo of a given address in Bogota
export const fetchlotcodigo = async (address) => {
  const url = 'https://catalogopmb.catastrobogota.gov.co/PMBWeb/web/api';
  const params = new URLSearchParams({
    cmd: 'geocodificar',
    apikey: 'e2d6f043-7b63-417e-8fbe-db515898576f',
    query: address
  });

  const headers = {
    'Accept': 'application/json, text/javascript, */*; q=0.01',
    'Accept-Language': 'en-US,en;q=0.9,es;q=0.8',
    'Connection': 'keep-alive',
    'Origin': 'https://mapas.bogota.gov.co',
    'Referer': 'https://mapas.bogota.gov.co/',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'cross-site',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
    'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"'
  };

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    const data = await response.json();
    // console.log('data:', data);
    const geolocation=[data.response.data.latitude, data.response.data.longitude];
    const lotcodigo=data.response.data.lotcodigo;
    // console.log('geolocation:', geolocation);
    return {geolocation, lotcodigo};
  } catch (error) {
    console.error('Error fetching geolocation:', error);
    return null;
  }
};

// Function to fetch the objectid of a given lotcodigo in Bogota
export const fetchobjectid = async (lotcodigo) => {
  const url = 'https://services1.arcgis.com/J5ltM0ovtzXUbp7B/ArcGIS/rest/services/lote/FeatureServer/0/query';
  const params = new URLSearchParams({
    f: 'json',
    where: `LOTCODIGO = '${lotcodigo}'`,
    returnGeometry: 'true',
    spatialRel: 'esriSpatialRelIntersects',
    outFields: '*'
  });

  const headers = {
    'Accept': '*/*',
    'Accept-Language': 'en-US,en;q=0.9,es;q=0.8',
    'Origin': 'https://mapas.bogota.gov.co',
    'Referer': 'https://mapas.bogota.gov.co/',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
    'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"',
    'sec-fetch-dest': 'empty',
    'sec-fetch-mode': 'cors',
    'sec-fetch-site': 'cross-site'
  };

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    const data = await response.json();
    const objectid=data.features[0]?.attributes?.OBJECTID || null;
    return {objectid};
  } catch (error) {
    console.error('Error fetching lot details:', error);
    return null;
  }
};

// function to fetch the properties of a given objectid in Bogota
export const fetchproperties = async (objectid) => {
  const url = 'https://services1.arcgis.com/J5ltM0ovtzXUbp7B/ArcGIS/rest/services/lote/FeatureServer/0/queryRelatedRecords';
  const params = new URLSearchParams({
    f: 'json',
    definitionExpression: '',
    relationshipId: 0,
    returnGeometry: 'true',
    objectIds: objectid,
    outFields: '*',
  });

  const headers = {
    'Accept': '*/*',
    'Accept-Language': 'en-US,en;q=0.9,es;q=0.8',
    'Origin': 'https://mapas.bogota.gov.co',
    // 'priority': 'u=1, i',
    'Referer': 'https://mapas.bogota.gov.co/',
    'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"',
    'sec-fetch-dest': 'empty',
    'sec-fetch-mode': 'cors',
    'sec-fetch-site': 'cross-site',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36'
  };

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    const data = await response.json();
    const properties=data.relatedRecordGroups[0]?.relatedRecords || null;
    // console.log('properties:', properties);   }
    return {properties};
  } catch (error) {
    console.error('Error fetching lot details:', error);
    return null;
  }
};

// function to orchestrate the fetching of geolocation and details of a given address in Bogota
export const fetchGeolocationAndDetailsBgta = async (address) => {
  const fetchlotcodigo_result = await fetchlotcodigo(address);
  const lotcodigo = fetchlotcodigo_result?.lotcodigo;
  if (!lotcodigo) {
    return null;
  }
  const objectid = await fetchobjectid(lotcodigo);
  // console.log('fetchlotcodigo_result:', fetchlotcodigo_result);
  // console.log('objectid:', objectid);

  const properties = await fetchproperties(objectid.objectid);

  return { fetchlotcodigo_result, objectid, properties};
};
