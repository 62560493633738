import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";


export default function PrivateRoutes(){    

    // get the user data from the context
    const {User}= useContext(AuthContext);    

    console.log("private router access user data " , " : " , User);
    console.log(User);

    // check if the user is logged to show the private routes    
    return(
        User ? <Outlet/> : <Navigate to="/"/>
    );
}