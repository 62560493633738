
export default function Instructivo() {
  
     
    const bienvenido=`
      
    1. Estimación de Valor de Propiedades: Obtén una estimación precisa del valor de una propiedad, ya sea para venta o renta, en cualquier lugar de Colombia. Solo ingresa la dirección o selecciona la zona en el mapa interactivo, y te proporcionaremos un análisis detallado con valores actuales del mercado.

    2. Consulta de Transacciones Recientes: ¿Quieres saber cuáles fueron las últimas transacciones inmobiliarias en una zona específica? Consulta las ventas recientes en el área de tu interés para tener una idea clara de las tendencias del mercado.

    3. Estudios de Mercado: Realiza un análisis exhaustivo del mercado inmobiliario. Podrás comparar la oferta y demanda de vivienda nueva y usada, visualizar la evolución de precios, y tomar decisiones informadas con datos actualizados.
     
    `
  
    return (
      <div className="flex-row">        
          <div className="px-5 mx-auto lg:px-20 sm:px-10 text-center">                                           
              <h1 className="mt-14 text-3x1 font-bold tracking-tight text-slate-800 sm:text-3xl"> ¡Bienvenido!</h1>
              <h3 className="mt-6 font-semibold text-slate-600 ">Estamos aquí para facilitarte el acceso a información clave del mercado inmobiliario en Colombia.</h3>
              <h4 className="mt-8 font-semibold text-slate-600 ">Con nuestra plataforma, puedes:</h4>              
          </div>       

          <div className="mt-10"></div>

          {/* services explanation */}
          <div className="text-slate-700">
            <p  className="mt-4">
              <strong>- Estimación de Valor de Propiedades:</strong> 
              {' '}Obtén una estimación precisa del valor de una propiedad, para venta o renta. Solo ingresa la dirección y ubica el lugar en el mapa interactivo, indica las caracteristicas del inmueble y te proporcionaremos una estimacion comercial con valores actuales y acordes a la oferta del mercado.
            </p>           

            <p className="mt-4">
              <strong>- Consulta de Transacciones Recientes:</strong> 
              {' '}¿Quieres saber cuáles fueron las últimas transacciones inmobiliarias en una zona específica? Consulta las ventas recientes en el área de tu interés para tener una idea clara de las tendencias del mercado, y el comporamiento de propiedades similares a la tuya.
            </p>

            <p className="mt-4">
              <strong>- Estudios de Mercado:</strong> 
              {' '}Realiza un análisis exhaustivo del mercado inmobiliario. Podrás comparar la oferta y demanda de vivienda nueva y usada, visualizar la evolución de precios, y tomar decisiones informadas con datos actualizados.
            </p>

          </div>

          {/* How it works */}            
          <div className="mt-10 text-slate-700">
            <h2 className="mt-2 text-2x1 font-bold tracking-tight text-slate-800 sm:text-2xl"> ¿Cómo Funciona?</h2>              
              <p  className="mt-4">
                <strong>1. Navegación Intuitiva:</strong> 
                {' '}En tu dashboard principal, verás un menú con las opciones de servicios. Simplemente selecciona el servicio que necesitas, sigue las indicaciones en pantalla, y obtendrás el reporte que buscas en pocos minutos. Podrás descargar la Información en formatos jpg, excel, etc.
              </p> 
              <p  className="mt-4">
                <strong>2. Personaliza tu Búsqueda: </strong> 
                {' '}Cada servicio te permitirá ajustar criterios específicos, como ubicación, tipo de propiedad, y características.
              </p>               
              <p  className="mt-4">
                <strong>3. Pago por servicio:</strong> 
                {' '}Nuestra plataforma opera con cargos al saldo de tu cuenta. Al seleccionar un servicio, se te indicará el costo y una vez generado el servicio, el valor será descontado de tu saldo. Si no tienes saldo suficiente, podrás recargar tu saldo de manera segura a través de nuestras opciones habilitadas.
              </p> 

          </div>

      </div>
    );
  }