import React from 'react';

const LoadingComponent = ({ msg, show, OnClose }) => {
  if (!show) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>        
        <div className='flex'>
          <div className='spinner' style={styles.spinner}></div>
          <p>{msg}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
};

const stylesGlobal = document.createElement('style');
stylesGlobal.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(stylesGlobal);

export default LoadingComponent;

// The @keyframes rule is used to create the spinning animation, and the stylesGlobal element is appended to the document head to ensure the animation works globally. The spinner will appear next to the loading message whenever the LoadingComponent is displayed. The component is hidden when the show prop is set to false.
