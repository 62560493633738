import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

// Define both age and area categories
const ageCategories = {
  '0-5 años': [0, 5],
  '6-10 años': [6, 10],
  '11-15 años': [11, 15],
  '16-20 años': [16, 20],
  'mas de 20 años': [21, Infinity],
};

const areas_dict_total = {
    'apartamento': {
        "Micro": [0, 30],
        "Pequeño": [30, 60],
        "Mediano": [60, 120],
        "Grande": [120, 250],
        "Extra grande": [250, 1000]
    },
    'casa': {
        "Micro": [0, 50],
        "Pequeño": [50, 120],
        "Mediano": [120, 250],
        "Grande": [250, 500],
        "Extra grande": [500, 1000],
        "Super grande": [1000, 2000],
        "Mega grande": [2000, 10000]
    },
};

// Function to calculate a moving average (while retaining the first non-null value)
const movingAverage = (data, windowSize) => {
  let result = Array(data.length).fill(null);

  if (data.length > 0) {
    // Keep the first non-null value
    const firstValueIndex = data.findIndex(val => val !== null);
    if (firstValueIndex !== -1) {
      result[firstValueIndex] = data[firstValueIndex];
    }

    // Apply the moving average after the first non-null value
    for (let i = firstValueIndex + 1; i < data.length; i++) {
      let sum = 0;
      let count = 0;

      // Calculate the average based on the window size
      for (let j = 0; j < windowSize && i - j >= firstValueIndex; j++) {
        sum += data[i - j];
        count++;
      }

      result[i] = sum / count;
    }
  }

  return result;
};

const PlotPricesCombined = ({ prices_to_plot_area, prices_to_plot_age, propertyType, area, age }) => {
  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    const areas_dict = areas_dict_total[propertyType];

    if ((prices_to_plot_area || prices_to_plot_age) && areas_dict) {
      const months = Object.keys(prices_to_plot_area || prices_to_plot_age);
      const categories_area = Object.keys(areas_dict);
      const categories_age = Object.keys(ageCategories);

      // Determine which area categories to plot based on the passed area
      let selectedAreaCategories = categories_area;
      if (area !== undefined) {
        selectedAreaCategories = categories_area.filter(category => {
          const [min, max] = areas_dict[category];
          return area >= min && area <= max;
        });
      }

      // Determine which age categories to plot based on the passed age
      let selectedAgeCategories = categories_age;
      if (age !== undefined) {
        selectedAgeCategories = categories_age.filter(category => {
          const [min, max] = ageCategories[category];
          return age >= min && age <= max;
        });
      }

      // Prepare data for area categories
      const areaData = selectedAreaCategories.map((category, index) => {
        const yValues = months.map(month => prices_to_plot_area[month]?.[category] || null);

        // Fill in missing values to maintain continuous lines
        for (let i = 1; i < yValues.length; i++) {
          if (!yValues[i]) {
            yValues[i] = yValues[i - 1];
          }
        }

        // Calculate the moving average to represent the trendline
        const smoothedValues = movingAverage(yValues, 6); // 6-month moving average

        return {
          x: months,
          y: smoothedValues,
          type: 'scatter',
          mode: 'lines',
          name: ``,
          line: {
            color: `hsl(${(index + 6) * 60}, 20%, 50%)`, // Different colors for age categories
          },
          hovertemplate: 'fecha: %{x}<br>$ %{y:,.1f} MM COP/m2',
        };
      });

      // Prepare data for age categories and fill the area between the lines
      const ageData = selectedAgeCategories.map((category, index) => {
        const yValues = months.map(month => prices_to_plot_age[month]?.[category] || null);

        // Fill in missing values to maintain continuous lines
        for (let i = 1; i < yValues.length; i++) {
          if (!yValues[i]) {
            yValues[i] = yValues[i - 1];
          }
        }

        // Calculate the moving average to represent the trendline
        const smoothedValues = movingAverage(yValues, 6); // 6-month moving average

        return {
          x: months,
          y: smoothedValues,
          type: 'scatter',
          mode: 'lines',
          fill: 'tonexty',  // Fill the area between this line and the previous one
          fillcolor: 'rgba(255, 0, 0, 0.2)',  // Light red fill color for the area
          name: ``,
          line: {
            color: `hsl(${(index + 6) * 60}, 20%, 50%)`, // Different colors for age categories
          },
          hovertemplate: 'fecha: %{x}<br> $ %{y:,.1f} MM COP/m2',
        };
      });

      const layout = {
        title: {
          text: `El área entre las dos líneas representa los rangos de venta ($MM COP) en los últimos 5 años para inmuebles construidos hace ${age} años y con áreas aproximadas de ${area} m2`,
          font: {
            size: 16, // Adjust the font size if needed
          },
        },
        xaxis: {
          title: '',
          tickangle: 0,
          tickfont: { size: 10 },
        },
        yaxis: {
          title: '',
          tickfont: { size: 12 },
        },
        showlegend: false,  // Hide the legend
        modebar: {
          orientation: 'v',
          x: 1,
          xanchor: 'right',
          y: 1,
          yanchor: 'top',
        },
        margin: { t: 80, b: 50, l: 50, r: 50 }, // Adjust the top margin
        autosize: true,  // Enable automatic resizing
      };

      setPlotData({ data: [...areaData, ...ageData], layout });
    } else {
      console.warn("prices_to_plot_area or prices_to_plot_age is undefined or empty");
      setPlotData(null);
    }
  }, [prices_to_plot_area, prices_to_plot_age, propertyType, area, age]);

  return (
    <div style={{ maxWidth: '100%', width: '100%', padding: '0 20px', boxSizing: 'border-box' }} className='mt-2'>
      {plotData && plotData.data.length>1 ? (
        <Plot 
          data={plotData.data} 
          layout={plotData.layout} 
          useResizeHandler={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <p>No hay suficiente informacion para construir una grafica de comportamiento de precios con relacion a la edad y antiguedad indicadas</p>
      )}
    </div>
  );
};

export default PlotPricesCombined;
